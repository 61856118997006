/* eslint-disable radix */

import React from 'react';
import Modal from 'lib/Modal';
import moment from 'lib/moment';
import DatePicker from 'lib/DatePicker';
import Spinner from 'lib/Spinner';

import Select from 'react-virtualized-select';
import {
  dynamoKeysToBase32,
  dynamoKeysFromBase32,
} from 'lib/helpers';

import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';
import { OrderCreationAttrs } from './types';

type SelectOption = {
  label: string;
  value: string;
};

const generateUniqueExternalID = (): string => {
  return moment().format('YYYYMMDD-HHmmss-SSS');
};

// TODO: This should be a setting instead
const disabledProviders = ['1927556-5'];

export const CreateRetroactiveOrderPopup = ({
  closePopup,
  transl,
}: {
  closePopup: () => void;
  transl: (label: string) => string;
}): JSX.Element => {
  const [providerAttrs, changeProviderAttrs] =
    React.useState<OrderCreationAttrs>({
      externalID: generateUniqueExternalID(),
      providerCompanyBusinessID: '',
      providerCompanyName: '',
      startAddressTitle: '',
      startAddress: '',
      startPostalCode: '',
      startAddressNotes: '-',
      startAddressPhone: '-',
      backupAddress: null,
      backupAddressPostalCode: null,
      backupPhone: null,
      recipientName: '',
      recipientAddress: '',
      recipientPostalCode: '',
      recipientPhoneNo: '-',
      recipientEmail: '-',
      recipientAddressNotes: '-',
      numPackages: 1,
      allowLeaveAtDoor: false,
      country: 'FI',
    });
  const [loading, setLoading] = React.useState<boolean>(false);

  const [selectedProviderCompany, changeSelectedProviderCompany] =
    React.useState(null as SelectOption | null);
  const [providerCompanyOptions, setProviderCompanyOptions] =
    React.useState(null as SelectOption[] | null);

  const [selectedCourier, changeSelectedCourier] = React.useState(
    null as SelectOption | null
  );
  const [courierOptions, setCourierOptions] = React.useState(
    null as SelectOption[] | null
  );

  const [startTimestamp, setStartTimestamp] = React.useState(
    moment().toISOString()
  );

  const [endTimestamp, setEndTimestamp] = React.useState(
    moment().toISOString()
  );

  const validate = (): boolean => {
    const falsableFields = [
      'backupAddress',
      'backupAddressPostalCode',
      'backupPhone',
      'allowLeaveAtDoor',
    ];
    for (const field of Object.keys(providerAttrs)) {
      if (!providerAttrs[field] && !falsableFields.includes(field)) {
        return false;
      }
    }
    if (!selectedCourier) return false;
    return true;
  };

  const setVal = (
    key: string,
    val: string,
    nullable: boolean,
    isNumber = false
  ): void => {
    let value: any = val;
    if (!value && nullable) value = null;
    if (isNumber) value = parseInt(val) || 0;
    changeProviderAttrs((oldAttrs) => ({
      ...oldAttrs,
      [key]: value,
    }));
  };

  const saveData = async (): Promise<void> => {
    if (!validate() || !selectedCourier?.value) return;
    const courierKeys = dynamoKeysFromBase32(selectedCourier?.value);
    if (!courierKeys || !courierKeys.rangeKey) return;
    setLoading(true);
    try {
      await Gateway.post(
        '/transportadmin/create-order-retroactively',
        {
          data: {
            providerAttrs,
            courierCompanyBusinessId: courierKeys.hashKey,
            courierEmail: courierKeys.rangeKey,
            startTimestamp,
            endTimestamp,
            priceForCourier: null,
            priceForMerchant: null,
          },
        }
      );
      setLoading(false);
      closePopup();
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  React.useEffect(() => {
    Gateway.get('/transportadmin/providercompanies').then((res) => {
      setProviderCompanyOptions(
        res
          .map((company) => ({
            value: company.businessID,
            label: company.name,
          }))
          .filter((item) => !disabledProviders.includes(item.value))
      );
    });
    Gateway.get('/transportadmin/list-all-couriers').then((res) =>
      setCourierOptions(
        res.map((courier) => ({
          label: `${courier.name} (${courier.email})`,
          value: dynamoKeysToBase32(
            courier.companyBusinessID,
            courier.email
          ),
        }))
      )
    );
  }, []);

  const providerCompanySelectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    changeSelectedProviderCompany(newSelection);

    setVal(
      'providerCompanyBusinessID',
      newSelection?.value ?? '',
      false
    );
    setVal('providerCompanyName', newSelection?.label ?? '', false);
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('AddOrderRetroactively')}</h2>
          <div className="form-group">
            <label>{transl('ChooseMerchant')}</label>
            {providerCompanyOptions && (
              // @ts-ignore
              <Select
                options={providerCompanyOptions}
                onChange={providerCompanySelectionChanged}
                value={selectedProviderCompany}
              />
            )}
          </div>
          <div className="form-group">
            <label>{transl('Courier')}</label>
            {courierOptions && (
              // @ts-ignore
              <Select
                options={courierOptions}
                onChange={changeSelectedCourier}
                value={selectedCourier}
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="addScheduleItemPopup-date">
              {transl('Date')}
            </label>
            <DatePicker
              selected={moment(startTimestamp).toDate()}
              className="form-control"
              onChange={(val): void => {
                if (val) {
                  setStartTimestamp(
                    moment(val)
                      .set({
                        hour: moment(startTimestamp).hour(),
                        minute: moment(startTimestamp).minute(),
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString()
                  );
                  setEndTimestamp(
                    moment(val)
                      .set({
                        hour: moment(endTimestamp).hour(),
                        minute: moment(endTimestamp).minute(),
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString()
                  );
                }
              }}
              dateFormat={'dd.MM.yyyy'}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addScheduleItemPopup-date">
              {transl('PickupTime')}
            </label>
            <input
              type="text"
              value={
                moment(startTimestamp).hour() > 0
                  ? moment(startTimestamp)
                      .hour()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let hour = parseInt(e.target.value, 10) || 0;
                if (hour < 0 || hour > 23) hour = 0;
                setStartTimestamp(
                  moment(startTimestamp)
                    .set({
                      hour,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />{' '}
            :{' '}
            <input
              type="text"
              value={
                moment(startTimestamp).minute() > 0
                  ? moment(startTimestamp)
                      .minute()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let minute = parseInt(e.target.value, 10) || 0;
                if (minute < 0 || minute > 59) minute = 0;
                setStartTimestamp(
                  moment(startTimestamp)
                    .set({
                      minute,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addScheduleItemPopup-date">
              {transl('DeliveryTimestamp')}
            </label>
            <input
              type="text"
              value={
                moment(endTimestamp).hour() > 0
                  ? moment(endTimestamp)
                      .hour()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let hour = parseInt(e.target.value, 10) || 0;
                if (hour < 0 || hour > 23) hour = 0;
                setEndTimestamp(
                  moment(endTimestamp)
                    .set({
                      hour,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />{' '}
            :{' '}
            <input
              type="text"
              value={
                moment(endTimestamp).minute() > 0
                  ? moment(endTimestamp)
                      .minute()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let minute = parseInt(e.target.value, 10) || 0;
                if (minute < 0 || minute > 59) minute = 0;
                setEndTimestamp(
                  moment(endTimestamp)
                    .set({
                      minute,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="rectroactiveordercreationpopup-startAddressTitle">
              {transl('StartAddressTitle')}
            </label>
            <input
              type="text"
              value={providerAttrs.startAddressTitle}
              className="form-control"
              id="rectroactiveordercreationpopup-startAddressTitle"
              maxLength={50}
              onChange={(e): void =>
                setVal('startAddressTitle', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="rectroactiveordercreationpopup-startAddress">
              {transl('StartAddress')}
            </label>
            <input
              type="text"
              value={providerAttrs.startAddress}
              className="form-control"
              id="rectroactiveordercreationpopup-startAddress"
              maxLength={50}
              onChange={(e): void =>
                setVal('startAddress', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="rectroactiveordercreationpopup-startPostalCode">
              {transl('StartPostalCode')}
            </label>
            <input
              type="text"
              value={providerAttrs.startPostalCode}
              className="form-control"
              id="rectroactiveordercreationpopup-startPostalCode"
              maxLength={50}
              onChange={(e): void =>
                setVal(
                  'startPostalCode',
                  e.target.value.trim(),
                  false
                )
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="rectroactiveordercreationpopup-recipientName">
              {transl('RecipientName')}
            </label>
            <input
              type="text"
              value={providerAttrs.recipientName}
              className="form-control"
              id="rectroactiveordercreationpopup-recipientName"
              maxLength={50}
              onChange={(e): void =>
                setVal('recipientName', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="rectroactiveordercreationpopup-recipientAddress">
              {transl('RecipientAddress')}
            </label>
            <input
              type="text"
              value={providerAttrs.recipientAddress}
              className="form-control"
              id="rectroactiveordercreationpopup-recipientAddress"
              maxLength={50}
              onChange={(e): void =>
                setVal('recipientAddress', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="rectroactiveordercreationpopup-recipientPostalCode">
              {transl('RecipientPostalCode')}
            </label>
            <input
              type="text"
              value={providerAttrs.recipientPostalCode}
              className="form-control"
              id="rectroactiveordercreationpopup-recipientPostalCode"
              maxLength={50}
              onChange={(e): void =>
                setVal(
                  'recipientPostalCode',
                  e.target.value.trim(),
                  false
                )
              }
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={!validate()}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
