/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import Gateway from '../web/gateway';
import { ChooseCourierComponent } from '../ChooseCourierComponent';

export const MarkOrderDeliveredPopup = ({
  closePopup,
  closeParentPopup,
  transl,
  transportOrderId,
  setLoading,
}: {
  closePopup: () => any;
  closeParentPopup: () => any;
  transl: (label: string) => string;
  transportOrderId: string;
  setLoading: (loading: boolean) => any;
}): JSX.Element => {
  const [courierBase32, setCourierBase32] =
    React.useState<string | null>(null);

  const saveData = (): void => {
    setLoading(true);
    Gateway.post(
      `/transportadmin/order/${transportOrderId}/courier/${courierBase32}/mark-delivered`,
      {}
    )
      .then(closeParentPopup)
      .catch((err) => {
        setLoading(false);
        alert(err);
      });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('MarkDelivered')}</h2>
          <p>{transl('MarkDeliveredMsg')}</p>
          <ChooseCourierComponent
            transl={transl}
            selectedCourierBase32Changed={setCourierBase32}
          />
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={!courierBase32}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('MarkDelivered')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
