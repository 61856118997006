import fi from './fi';
import en from './en';

const translations = {
  fi,
  en
};

export const gettext = (lang: string) => (label: string): string =>
  translations[lang][label];
