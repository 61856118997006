/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import { ChooseCourierComponent } from '../ChooseCourierComponent';
import { reallocateTransportOrderPackage } from './requests';
import { SamedayOrderPackage } from './types';
import Spinner from 'lib/Spinner';

export const ReallocateOrderPackagePopup = ({
  closePopup,
  transl,
  attrs,
}: {
  closePopup: () => any;
  transl: (label: string) => string;
  attrs: SamedayOrderPackage;
}): JSX.Element => {
  const [courierBase32, setCourierBase32] = React.useState<
    string | null
  >(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const saveData = (): void => {
    if (!courierBase32) return;
    setLoading(true);
    reallocateTransportOrderPackage(attrs.id, courierBase32)
      .then(closePopup)
      .catch((err) => {
        setLoading(false);
        alert(err);
      });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('ChangeCourier')}</h2>
          <p>{transl('ChooseCourierToReceiveAllChosenOrders')}</p>
          <ChooseCourierComponent
            transl={transl}
            selectedCourierBase32Changed={setCourierBase32}
          />
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={!courierBase32}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('ChangeCourier')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
