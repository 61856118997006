import React from 'react';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import Modal from 'lib/Modal';
import { dynamoKeysToBase32 } from 'lib/helpers';
import { CourierOnlineData } from './types';
import { TransportOrder } from '../orders/types';
import { ManualAllocationPopup } from './ManualAllocationPopup';
import moment from 'lib/moment';
import { fetchAllCouriersOnline } from '../samedayOrders/requests';

import Gateway from '../web/gateway';

const CourierRealtimePopup = ({
  closePopup,
  transl,
  attrs,
  couriersOnline,
}: {
  closePopup: () => any;
  transl: (label: string) => string;
  attrs: CourierOnlineData;
  couriersOnline: CourierOnlineData[];
}): JSX.Element => {
  const [currentOrders, setCurrentOrders] = React.useState(
    null as TransportOrder[] | null
  );
  const [reallocateOrderPopupAttrs, setReallocateOrderPopupAttrs] =
    React.useState<TransportOrder | null>(null);

  const refresh = React.useCallback((): any => {
    setCurrentOrders(null);
    Gateway.get(
      `/transportadmin/currentordersforcourier/${dynamoKeysToBase32(
        attrs.courier.companyBusinessID,
        attrs.courier.email
      )}`
    ).then(setCurrentOrders);
  }, [attrs]);

  const [allCouriersOnline, setAllCouriersOnline] = React.useState(
    null as CourierOnlineData[] | null
  );

  React.useEffect(() => {
    fetchAllCouriersOnline().then((data) =>
      setAllCouriersOnline(data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  React.useEffect(() => {
    refresh();
    return (): void => {
      // Intentionally empty
    };
  }, [refresh]);

  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${attrs.courier.currentLocationLat},${attrs.courier.currentLocationLong}`;

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '450px',
      maxHeight: window.innerHeight - 100,
      width: '1200px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'htr4y3e4'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('RealtimeOrdersForCourier')}</h2>
          <h4>
            {attrs.courier.name} ({attrs.courier.email})
          </h4>
          <p>
            <a href={googleMapsLink} rel="noreferrer" target="_blank">
              {transl('MapLocation')}
            </a>
            &nbsp; (
            {attrs.courier.lastLocationUpsyncTimestamp &&
              moment().diff(
                moment(attrs.courier.lastLocationUpsyncTimestamp),
                'seconds'
              ) +
                ' ' +
                transl('SecondsAgo')}
            )
          </p>
          {currentOrders === null && <LoadingComponent />}
          {currentOrders !== null && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>{transl('OrderNumber')}</th>
                  <th>{transl('State')}</th>
                  <th>{transl('NumberOfPackages')}</th>
                  <th>{transl('Timestamp')}</th>
                  <th>{transl('Sender')}</th>
                  <th>{transl('Recipient')}</th>
                  <th>{transl('PickupTime')}</th>
                  <th>{transl('Actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.map((order, i) => (
                  <tr key={i}>
                    <td>{order.externalID}</td>
                    <td>
                      {transl(
                        !order.failureNote
                          ? order.state
                          : 'deliveryFailed'
                      )}
                    </td>
                    <td>{order.numPackages}</td>
                    <td>
                      {moment(
                        order.orderPlacedTimestamp as string
                      ).format('L')}{' '}
                      {moment(
                        order.orderPlacedTimestamp as string
                      ).format('HH:mm')}
                    </td>
                    <td>
                      {order.providerCompanyName}
                      <br />
                      {order.startAddressTitle}
                      <br />
                      {order.startAddress}
                      <br />
                      {order.startPostalCode}
                      <br />
                      {order.startAddressPhone}
                    </td>
                    <td>
                      {order.recipientName}
                      <br />
                      {order.recipientAddress}
                      <br />
                      {order.recipientPostalCode}
                    </td>
                    <td>
                      {!!order.earliestPickupTime
                        ? moment(order.earliestPickupTime).format(
                            'L'
                          ) +
                          ' ' +
                          moment(order.earliestPickupTime).format(
                            'HH:mm'
                          )
                        : order.serviceLevel === 'samehour'
                        ? transl('Now')
                        : '-'}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-md"
                        onClick={(): any =>
                          setReallocateOrderPopupAttrs(order)
                        }
                      >
                        {transl('Actions')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Modal>
      {reallocateOrderPopupAttrs && (
        <ManualAllocationPopup
          closePopup={closePopup}
          transl={transl}
          attrs={reallocateOrderPopupAttrs}
          couriersOnline={allCouriersOnline ?? couriersOnline}
        />
      )}
    </div>
  );
};

export const CouriersOnlineView = ({
  transl,
  couriers,
  refresh,
}: {
  transl: (label: string) => string;
  couriers: CourierOnlineData[];
  nofUnallocatedOrders: number;
  refresh: () => any;
}): JSX.Element => {
  const [popupData, setPopupData] = React.useState(
    null as CourierOnlineData | null
  );

  return (
    <div>
      <h3>{transl('CouriersWhoAreOnline')}</h3>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('Name')}</th>
            <th>{transl('Phone')}</th>
            <th>{transl('OrdersInDelivery')}</th>
          </tr>
        </thead>
        <tbody>
          {couriers.map((attrs, i) => (
            <tr
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={(): any => setPopupData(attrs)}
            >
              <td>{attrs.courier.name}</td>
              <td>{attrs.courier.phone}</td>
              <td>{attrs.undelivered}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {popupData && (
        <CourierRealtimePopup
          transl={transl}
          attrs={popupData}
          couriersOnline={couriers}
          closePopup={(): any => {
            setPopupData(null);
            refresh();
          }}
        />
      )}
    </div>
  );
};
