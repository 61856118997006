import React from 'react';
import moment from 'lib/moment';
import { commonStyles } from 'lib/commonStyles';
import {
  dynamoKeysToBase32,
  dynamoKeysFromBase32,
} from 'lib/helpers';
import momentTz from 'moment-timezone';
import Spinner from 'lib/Spinner';

import {
  SpecialOrder,
  ScheduleItem,
  TransportCourier,
  weekdays,
  SpecialOrderWeeklySchedule,
  updateSchedule,
} from './helpers';

const getRandomColor = (): string => {
  const letters = '0123456789ABC';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 13)];
  }
  return color;
};
const cachedColors: { [courierLabel: string]: string } = {};

const getColorForCourier = (courierLabel: string): string => {
  if (!cachedColors[courierLabel]) {
    cachedColors[courierLabel] = getRandomColor();
  }
  return cachedColors[courierLabel];
};

type WeekdayTemplateItem = {
  orderCreationTimestamp: string;
  timeUntilEarliestPickupTimeMinutes: number;
};

type Weekday = {
  date: string;
  template: WeekdayTemplateItem[];
};

const getTimestamp = (
  date: string,
  hour: number,
  minute: number,
  timezone: string
): string => {
  const res = momentTz.tz(
    `${date} ${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`,
    timezone
  );
  return res.toISOString();
};

const getWeek = (
  startDateISO: string,
  template: SpecialOrderWeeklySchedule
): Weekday[] => {
  const res: Weekday[] = [];
  for (let i = 0; i < 7; i++) {
    const d = moment(startDateISO)
      .add(i, 'days')
      .format('YYYY-MM-DD');
    const weekd = weekdays[i];
    const sched = template[weekd] || [];

    const templ: WeekdayTemplateItem[] = sched.map((attrs) => ({
      orderCreationTimestamp: getTimestamp(
        d,
        attrs.orderCreationHour,
        attrs.orderCreationMinute,
        template.timezone ?? 'Europe/Helsinki'
      ),
      timeUntilEarliestPickupTimeMinutes:
        attrs.timeUntilEarliestPickupTimeMinutes ?? 0,
    }));

    templ.sort((a, b) =>
      a.orderCreationTimestamp.localeCompare(b.orderCreationTimestamp)
    );

    res.push({
      date: d,
      template: templ,
    });
  }
  return res;
};

type ScheduleItemsByTimestamp = {
  [timestamp: string]: ScheduleItem;
};

type CouriersByBase32 = {
  [base32Id: string]: TransportCourier;
};

const DayView = ({
  day,
  chosenCourierId,
  scheduledItems,
  couriers,
  removeEvent,
  addEvent,
  transl,
}: {
  day: Weekday;
  chosenCourierId: string | null;
  scheduledItems: ScheduleItemsByTimestamp;
  couriers: CouriersByBase32;
  removeEvent: (timestamp: string) => any;
  addEvent: (evt: ScheduleItem) => any;
  transl: (label: string) => string;
  key: number;
}): JSX.Element | null => {
  return (
    <div
      style={{
        ...commonStyles.defaultContainer,
        border: '1px solid black',
        minWidth: '200px',
      }}
    >
      <h3>{moment(day.date).format('dd L')}</h3>
      {day.template.map((item, i) => {
        const evt = scheduledItems[item.orderCreationTimestamp];
        const orderCreationTimestamp = moment(
          item.orderCreationTimestamp
        );
        const pickupTime = moment(item.orderCreationTimestamp).add(
          item.timeUntilEarliestPickupTimeMinutes,
          'minutes'
        );
        const hasPassed = orderCreationTimestamp.isBefore(moment());
        const borderColor = hasPassed
          ? 'grey'
          : !evt
          ? 'red'
          : 'black';
        const editable =
          (evt &&
            chosenCourierId &&
            evt.courierBase32 === chosenCourierId &&
            !hasPassed) ||
          (!evt && chosenCourierId && !hasPassed);
        const selected =
          editable && evt && evt.courierBase32 === chosenCourierId;

        const toggle = (): void => {
          if (!editable) return;
          if (selected) {
            removeEvent(item.orderCreationTimestamp);
          } else {
            addEvent({
              courierBase32: chosenCourierId,
              orderCreationTimestamp: item.orderCreationTimestamp,
              timeToPickupMinutes: null,
              timeUntilEarliestPickupTimeMinutes:
                item.timeUntilEarliestPickupTimeMinutes,
            });
          }
        };

        let courierLabel = '';
        if (evt && evt.courierBase32) {
          if (couriers[evt.courierBase32]) {
            courierLabel = `${couriers[evt.courierBase32].name} (${
              couriers[evt.courierBase32].email
            })`;
          } else {
            const keys = dynamoKeysFromBase32(evt.courierBase32);
            courierLabel = keys?.hashKey ?? '';
          }
        }
        if (hasPassed && !evt) {
          courierLabel = transl('Passed');
        }
        return (
          <div
            key={i}
            style={{
              padding: '10px',
              border: `2px solid ${borderColor}`,
              height: '80px',
              cursor: editable ? 'pointer' : 'auto',
            }}
            onClick={toggle}
          >
            <b>{pickupTime.format('HH:mm')}</b>
            <br />
            {editable && (
              <input
                type="checkbox"
                checked={selected ?? false}
                onChange={toggle}
              />
            )}
            {!editable && (
              <p
                style={{
                  color: hasPassed
                    ? 'grey'
                    : evt && evt.courierBase32
                    ? getColorForCourier(evt.courierBase32)
                    : 'black',
                }}
              >
                {courierLabel}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const SpecialOrderScheduleCalendarView = ({
  chosenCourierId,
  startDateISO,
  chosenOrder,
  transl,
  couriers,
  reload,
}: {
  chosenCourierId: string | null;
  startDateISO: string;
  chosenOrder?: SpecialOrder;
  transl: (label: string) => string;
  couriers: TransportCourier[];
  reload: () => Promise<any>;
}): JSX.Element | null => {
  const [loading, setLoading] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [scheduleItems, setScheduleItems] =
    React.useState<ScheduleItemsByTimestamp>({});
  const [couriersByBase32, setCouriersByBase32] =
    React.useState<CouriersByBase32>({});

  React.useEffect(() => {
    if (chosenOrder) {
      const oneDayAgo = moment().subtract(1, 'day');
      const items: ScheduleItemsByTimestamp = {};
      for (const item of chosenOrder.schedule) {
        if (moment(item.orderCreationTimestamp).isAfter(oneDayAgo)) {
          items[item.orderCreationTimestamp] = item;
        }
      }
      setScheduleItems(items);
    } else {
      setScheduleItems({});
    }
    const couriersById: CouriersByBase32 = {};
    for (const courier of couriers) {
      couriersById[
        dynamoKeysToBase32(courier.companyBusinessID, courier.email)
      ] = courier;
    }
    setCouriersByBase32(couriersById);
  }, [chosenOrder, couriers]);

  if (!chosenOrder) return null;

  const week = getWeek(startDateISO, chosenOrder.weeklyTemplate);

  const removeEvent = (timestamp: string): void => {
    if (!scheduleItems[timestamp]) return;
    setHasChanges(true);
    const newScheduleItems = JSON.parse(
      JSON.stringify(scheduleItems)
    );
    delete newScheduleItems[timestamp];
    setScheduleItems(newScheduleItems);
  };
  const addEvent = (evt: ScheduleItem): void => {
    if (!evt.orderCreationTimestamp) return;
    setHasChanges(true);
    const newScheduleItems = JSON.parse(
      JSON.stringify(scheduleItems)
    );
    newScheduleItems[evt.orderCreationTimestamp] = evt;
    setScheduleItems(newScheduleItems);
  };

  const saveData = async (): Promise<void> => {
    setLoading(true);
    try {
      const schedule = Object.values(scheduleItems);
      schedule.sort((a, b) =>
        a.orderCreationTimestamp.localeCompare(
          b.orderCreationTimestamp
        )
      );
      await updateSchedule(chosenOrder.id, schedule);
      await reload();
    } finally {
      setLoading(false);
      setHasChanges(false);
    }
  };

  return (
    <div style={commonStyles.defaultContainer}>
      <div style={commonStyles.horizontalContainer}>
        {week.map((item, i) => (
          <DayView
            key={i}
            day={item}
            chosenCourierId={chosenCourierId}
            scheduledItems={scheduleItems}
            couriers={couriersByBase32}
            removeEvent={removeEvent}
            addEvent={addEvent}
            transl={transl}
          />
        ))}
      </div>
      <div style={{ paddingTop: '15px', width: '200px' }}>
        <button
          type="button"
          className="btn btn-primary btn-lg btn-block"
          onClick={saveData}
          disabled={!hasChanges}
        >
          <i className="fa fa-floppy-o"></i>
          &nbsp;&nbsp;{transl('Save')}
        </button>
      </div>
      <Spinner visible={loading} />
    </div>
  );
};
