import React from 'react';
import Select from 'react-virtualized-select';
import { dynamoKeysToBase32 } from 'lib/helpers';
import { LoadingComponent } from 'lib/LoadingComponent';
import { TransportCourier } from './transportCouriers/types';

import Gateway from './web/gateway';

type SelectOption = {
  label: string;
  value: string;
};

export const ChooseCourierComponent = ({
  transl,
  selectedCourierBase32Changed,
}: {
  transl: (label: string) => string;
  selectedCourierBase32Changed: (courierBase32: string | null) => any;
}): JSX.Element => {
  const [couriers, setCouriers] = React.useState(
    null as TransportCourier[] | null
  );
  const [chosenCourierId, setChosenCourierId] = React.useState(
    null as string | null
  );

  React.useEffect(() => {
    Gateway.get('/transportadmin/list-all-couriers').then((data) =>
      setCouriers(data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  if (!couriers) {
    return <LoadingComponent />;
  }

  const courierOptions: SelectOption[] = couriers.map((courier) => ({
    label: `${courier.name} (${courier.email})`,
    value: dynamoKeysToBase32(
      courier.companyBusinessID,
      courier.email
    ),
  }));
  const courierSelectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    setChosenCourierId(newSelection ? newSelection.value : null);
    selectedCourierBase32Changed(
      newSelection ? newSelection.value : null
    );
  };
  const currentCourierSelectOption = courierOptions.find(
    (item) => item.value === chosenCourierId
  );

  return (
    <div className="form-group">
      <label>{transl('ChooseCourier')}</label>
      <Select
        options={courierOptions}
        // @ts-ignore
        onChange={courierSelectionChanged}
        value={currentCourierSelectOption}
      />
    </div>
  );
};
