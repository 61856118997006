import React from 'react';
import Colors from 'lib/colors';
import { commonStyles } from 'lib/commonStyles';
import {
  login,
  loginWithAdminToken,
  makeRawRequest,
} from './web/tokens';
import { useEventListener } from './lib/useEventListener';
import { LoadingComponent } from './lib/LoadingComponent';

const isCabappAdminCompany = (companyBusinessId: string): boolean =>
  companyBusinessId === '3100788-9';

const CredentialsView = ({
  logUserIn,
  companyBusinessId,
  companyName,
  transl,
}: {
  logUserIn: () => void;
  companyBusinessId: string;
  companyName: string;
  transl: (label: string) => string;
}): JSX.Element => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(false);

  const loginPressed = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    setLoading(true);
    login(companyBusinessId, email, password)
      .then(logUserIn)
      .catch(() => {
        setLoading(false);
        setHasErrors(true);
      });
  };

  return (
    <div
      style={{
        ...commonStyles.flexColumnContainer,
        ...styles.container,
      }}
    >
      <form
        style={styles.main}
        onSubmit={loginPressed}
        autoComplete="off"
      >
        <h3 style={commonStyles.textCenter}>
          {transl('LoginToCompany').replace('#COMPANY#', companyName)}
        </h3>
        {hasErrors && (
          <p style={{ color: 'red' }}>
            {transl('IncorrectCredentialsError')}
          </p>
        )}
        <div className="form-group">
          <label htmlFor="loginviewEmail">{transl('Email')}</label>
          <input
            type="text"
            autoComplete="off"
            value={email}
            className="form-control"
            id="loginviewEmail"
            name="loginviewEmail"
            onChange={(e): void => setEmail(e.target.value)}
          />
          <label htmlFor="loginviewpassword">
            {transl('Password')}
          </label>
          <input
            type="password"
            autoComplete="off"
            value={password}
            className="form-control"
            id="loginviewpassword"
            name="loginviewpassword"
            onChange={(e): void => setPassword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block"
          disabled={loading}
        >
          {transl('Login')}
        </button>
        <br />
        <br />
        {isCabappAdminCompany(companyBusinessId) && (
          <a
            href={`https://sso.cabapp.fi/admin/login?loginflow=transportadmin`}
            className="btn btn-default btn-lg btn-block"
          >
            {transl('LoginWithGoogle')}
          </a>
        )}
      </form>
    </div>
  );
};

const CompanyBidView = ({
  hasErrors,
  transl,
}: {
  hasErrors: boolean;
  transl: (label: string) => string;
}): JSX.Element => {
  const [companyBusinessId, setCompanyBusinessId] = React.useState(
    window.location.hash.replace('#/', '')
  );

  const setCompanyPressed = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    window.location.hash = '/' + companyBusinessId;
  };

  return (
    <div
      style={{
        ...commonStyles.flexColumnContainer,
        ...styles.container,
      }}
    >
      <form
        style={{ ...styles.main, maxWidth: '500px' }}
        onSubmit={setCompanyPressed}
        autoComplete="off"
      >
        <h3 style={commonStyles.textCenter}>
          {transl('EnterCompanyBid')}
        </h3>
        {hasErrors && (
          <p style={{ color: 'red' }}>
            {transl('CompanyNotFoundError')}
          </p>
        )}
        <div className="form-group">
          <label htmlFor="loginviewcompanybid">
            {transl('CompanyBid')}
          </label>
          <input
            type="text"
            autoComplete="off"
            value={companyBusinessId}
            className="form-control"
            id="loginviewcompanybid"
            name="loginviewcompanybid"
            onChange={(e): void =>
              setCompanyBusinessId(e.target.value)
            }
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg btn-block"
        >
          {transl('GoToLogin')}
        </button>
        <br />
      </form>
    </div>
  );
};

const LoginView = ({
  logUserIn,
  transl,
  companyName,
  setCompanyName,
}: {
  logUserIn: () => void;
  transl: (label: string) => string;
  companyName: string | null;
  setCompanyName: (name: string | null) => any;
}): JSX.Element => {
  const [isLoading, setisLoading] = React.useState(false);
  const [companyBusinessId, setCompanyBusinessId] = React.useState(
    window.location.hash.replace('#/', '')
  );

  const handler = React.useCallback((): any => {
    const bid = window.location.hash.replace('#/', '');

    setCompanyBusinessId(bid);
    setisLoading(true);
    makeRawRequest('GET', '/companynames/' + bid)
      .then((res: any) => {
        setisLoading(false);
        setCompanyName(res.name);
      })
      .catch(() => {
        setisLoading(false);
        setCompanyName(null);
      });
  }, [setisLoading, setCompanyName, setCompanyBusinessId]);

  useEventListener('hashchange', handler);

  React.useEffect(() => {
    let bid = window.location.hash.replace('#/', '');
    if (bid) {
      // Functionality for admin token login for cabapp admin company
      if (
        bid.length > 30 &&
        isCabappAdminCompany(bid.substring(0, 9))
      ) {
        setisLoading(true);
        const adminToken = bid.substring(9);
        bid = bid.substring(0, 9);
        loginWithAdminToken(bid, adminToken)
          .then(() => {
            setisLoading(false);
            logUserIn();
            window.location.hash = '#/' + bid;
          })
          .catch(() => {
            setisLoading(false);
          });
      } else {
        setCompanyBusinessId(bid);
        setisLoading(true);
        makeRawRequest('GET', '/companynames/' + bid)
          .then((res: any) => {
            setisLoading(false);
            setCompanyName(res.name);
          })
          .catch(() => {
            setisLoading(false);
            setCompanyName(null);
          });
      }
    }
    return (): void => {
      // intentionally empty
    };
  }, [setCompanyName, logUserIn]);

  if (isLoading) return <LoadingComponent />;

  if (!companyName)
    return (
      <CompanyBidView
        hasErrors={companyBusinessId.length > 0}
        transl={transl}
      />
    );

  return (
    <CredentialsView
      logUserIn={logUserIn}
      companyBusinessId={companyBusinessId}
      companyName={companyName}
      transl={transl}
    />
  );
};

const styles = {
  container: {
    backgroundColor: '#ddd',
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    borderRadius: '20px',
    borderWidth: '1px',
    borderColor: Colors.main,
    borderStyle: 'solid',
    backgroundColor: '#eee',
    padding: '20px',
    width: '95%',
    maxWidth: '400px',
  },
};

export default LoginView;
