const fi: any = {
  DecimalSeparator: ',',
  EnterCompanyBid:
    "Aloita syöttämällä yrityksen y-tunnus ja klikkaamalla 'Siirry kirjautumaan'",
  CompanyBid: 'Y-tunnus',
  CompanyNotFoundError:
    'Yritystä annetulla y-tunnuksella ei löytynyt. Ole hyvä ja yritä uudelleen.',
  GoToLogin: 'Siirry kirjautumaan',
  LoginToCompany: 'Kirjaudu sisään yritykseen #COMPANY#',
  Email: 'Sähköposti',
  Password: 'Salasana',
  Login: 'Kirjaudu sisään',
  IncorrectCredentialsError:
    'Kirjautuminen epäonnistui. Tarkista syöttämäsi tunnukset ja yritä uudelleen',
  Logout: 'Kirjaudu ulos',
  Reports: 'Raportointi',
  Couriers: 'Kuljettajien hallinta',
  NewCourier: 'Uusi kuljettaja',
  AddNewCourier: 'Lisää uusi kuljettaja',
  ModifyCourier: 'Muokkaa kuljettajan tietoja',
  Name: 'Nimi',
  Delete: 'Poista',
  ResetCredentials: 'Nollaa salasana',
  ConfirmDeleteCourier:
    'Vahvista kuljettajan "#NAME#" kirjautumistunnusten poistaminen',
  ConfirmResetCourier:
    'Vahvista kuljettajan "#NAME#" kirjautumistunnusten resetoiminen ja uuden salasanan lähettäminen sähköpostitse',
  Confirm: 'Hyväksy',
  TransportOrderHistory: 'Kuljetustilaushistoria',
  orderPlaced: 'Odottaa kuljetusta',
  courierChosen: 'Kuljettaja valittu',
  pickedUp: 'Kuljetuksessa',
  delivered: 'Luovutettu vastaanottajalle',
  pickupFailed: 'Nouto epäonnistui',
  deliveryFailed: 'Toimitus epäonnistui',
  cancelled: 'Peruttu',
  OrderPlacedTimestamp: 'Tilaus jätetty',
  CourierChosenTimestamp: 'Kuljettaja valittu',
  PickedUpTimestamp: 'Otettu kuljetukseen',
  DeliveredTimestamp: 'Luovutettu vastaanottajalle',
  State: 'Tila',
  FromAddress: 'Lähtöpaikka',
  ToAddress: 'Päämäärä',
  DistanceinKm: 'Etäisyys (km)',
  DistanceinKmStraightline: 'Etäisyys (km, linnuntie)',
  DistanceinKmActual: 'Etäisyys (km, ajomatka)',
  DistanceinKmRoute: 'Etäisyys (km, ajomatka, koko reitti)',
  DoSearch: 'Tee haku',
  CourierResetComplete:
    'Kuljettajan kirjautumistunnukset on nyt resetoitu ja lähetetty sähköpostitse',
  Phone: 'Puhelin',
  Language: 'Kieli',
  IsSubcontractor: 'Kuljettaja on alihankkija',
  SubcontractorData: 'Yrityksen tiedot',
  IBAN: 'IBAN',
  CompanyName: 'Yrityksen nimi',
  CompanyAddress: 'Yrityksen osoite',
  PostalCode: 'Yrityksen postinumero',
  PostalCity: 'Yrityksen postitoimipaikka',
  CompanyEmail: 'Yrityksen sähköpostiosoite',
  Payday: 'Maksupäivä',
  Payperiod: 'Maksukausi',
  Totalsum: 'Summa',
  NumberOfOrders: 'Kuljetukset yht.',
  OrdersByCourier: 'Kuljettajakohtaiset toimitukset',
  SumWithoutVat: 'Veroton',
  Vat: 'Alv',
  SumWithVat: 'Verollinen',
  Courier: 'Kuljettaja',
  Date: 'Päiväys',
  Orders: 'Toimitukset',
  DeliveryTimestamp: 'Toimitusaika',
  Area: 'Alue',
  SumWithoutVatWithExplanation: 'Summa (Veroton)',
  Timestamp: 'Aikaleima',
  OrderNotFound:
    'Kuljetustilausta ei löytynyt. Varmista, että syötit sekä tilausnumeron että lähettäjän oikein.',
  OrderNumber: 'Tilausnumero/tunniste',
  Sender: 'Lähettäjä',
  ChooseSender: 'Valitse lähettäjä',
  ChooseCourier: 'Valitse kuljettaja',
  FetchOrderBasedOnOrderNumberAndSender:
    'Hae toimitusta syöttämällä lähettäjä ja tilausnumero',
  OrderSearch: 'Toimituksen tiedot',
  SignatureImg: 'Kuva allekirjoituksesta',
  LeftAtDoorImg: 'Kuva ovelle jätetystä toimituksesta',
  CourierInformation: 'Kuljettajan tiedot',
  OrganizationID: 'Organisaatiotunnus',
  CurrentVehicle: 'Ajoneuvon tiedot',
  Yes: 'Kyllä',
  No: 'Ei',
  PickupPlaceInformation: 'Noutopaikan tiedot',
  CustomerInformation: 'Asiakkaan tiedot',
  Address: 'Osoite',
  DeliveryInstructions: 'Toimitusohjeet',
  PickupInstructions: 'Nouto-ohjeet',
  OrderInformation: 'Tilauksen tiedot',
  ReturnAddressInformation: 'Palautusosoitteen tiedot',
  PickupFailReason: 'Epäonnistumisen syy',
  cantFindPickupSpot:
    'Noutopaikkaa ei löytynyt eikä noutopaikan puhelimeen vastattu',
  cantGetToPickupSpot: 'Noutopaikka löytyi mutta sinne ei pääse',
  packageInvalid: 'Paketti ei täytä Cabappin ehtoja',
  packageNotAvailable: 'Paketti ei ollut valmis kuskin saapuessa',
  FailureReason: 'Epäonnistumisen kuvaus',
  NumberOfPackages: 'Pakettien lukumäärä',
  ContactlessDelivery: 'Kontaktiton toimitus',
  Events: 'Tapahtumat',
  ReturnedTimestamp: 'Palautettu lähettäjälle',
  Refresh: 'Päivitä',
  Monitoring: 'Monitorointi',
  UnallocatedOrders: 'Kohdistamattomia tilauksia',
  OrdersRejectedByCouriers: 'Manuaalisesti kohdennettavia tilauksia',
  OrdersInDelivery: 'Kuljetettavana olevia tilauksia',
  CouriersOnline: 'Kuriireja online-tilassa',
  CouriersWhoAreOnline: 'Kuriirit Online-tilassa',
  CouriersWhoAreOffline: 'Kuriirit Offline-tilassa',
  OrdersThatAreUnallocated: 'Kohdistamattomat tilaukset',
  OrdersThatAreRejectedByCouriers:
    'Manuaalisesti kohdennettavat tilaukset',
  Recipient: 'Vastaanottaja',
  AllocateOrder: 'Kohdista tilaus',
  RealtimeOrdersForCourier: 'Kuljetettavana olevat tilaukset',
  AllocateToCourier: 'Kohdistetaanko tilaus kuriirille #COURIER#?',
  UnallocateOrder: 'Vapauta tilaus',
  ConfirmUnallocateFromCourier:
    'Vapautetaanko tilaus #ORDER_NO# kuljettajalta #COURIER#?',
  LoginWithGoogle: 'Kirjaudu sisään Google-tunnuksilla',
  PickupTime: 'Noutoaika',
  Now: 'Heti',
  Save: 'Tallenna',
  RegularOrders: 'Säännölliset kuljetukset',
  RetroactivelyCreated: 'Luotu jälkikäteen',
  ChangeCourier: 'Vaihda kuljettajaa',
  CheckIBANMessage:
    'IBAN vaikuttaa virheelliseltä. Tarkista se ja yritä uudelleen',
  AddCourierToGroup: 'Lisää kuljettajaryhmään',
  Remove: 'Poista',
  AllCouriers: 'Kaikki kuljettajat',
  PhoneForNotifications: 'Puhelinnumero ilmoituksille',
  CancelOrder: 'Peru tilaus',
  ConfirmCancelOrder: 'Vahvista tilauksen peruminen',
  RetainPaymentsMsg:
    'Säilytä laskutuksessa (Jos tämä on valittuna, kyydistä tehtyä laskutusta ja kuskin palkkiota ei peruuteta)',
  MarkDelivered: 'Merkitse toimitetuksi',
  MarkDeliveredMsg:
    'Valitse listasta kuski joka on toimittanut tämän tilauksen. HUOM! Käytä tätä vain jos tilaus on jo toimitettu. Jos tilauksen toimittamisen on kesken, odota että kuski on vahvistanut tilauksen toimitetuksi ennen kuin jatkat.',
  UnallocatedSamedayOrders: 'Kohdistamattomat',
  SamedayOrderPackages: 'Kohdistetut',
  SamedayOrders: 'Saman päivän toimitukset',
  Selected: 'Valittu',
  PickupAddress: 'Nouto-osoite',
  SendSelectedToCourier: 'Lähetä valitut kuljettajalle',
  ConfirmCreateOrderPackage:
    'Vahvista valittujen tilausten kohdentaminen valitulle kuskille. Tilauksia tulle olla valittuna vähintään kuusi aina kun mahdollista. Alle neljän tilauksen kuljettaminen on tappiollista.',
  PleaseSendAtLeast4OrdersMessage:
    'Alle neljän tilauksen kuljettaminen kerralla on tappiollista. Valitse vähintään neljä tilausta ennen kuin lähetät ne kuskille, jos vain suinkin mahdollista',
  PleaseSendAtLeast6OrdersMessage:
    'Jos mahdollista, lähetä kuskille vähintään kuusi tilausta kerralla, jotta Cabappin toiminta on varmasti voitollista.',
  City: 'Kaupunki',
  OrderType: 'Tilauksen tyyppi',
  OrderTypeSameHour: 'Pikatoimitus (30-120min)',
  OrderTypeSameDay: 'Saman päivän toimitus (4h)',
  SentToCourier: 'Lähetetty kuljettajalle',
  WaitingToBeSentToCourier: 'Odottaa lähettämistä kuljettajalle',
  RouteCalculationInProgress: 'Reitin laskenta käynnissä',
  ChooseCourierToReceiveAllChosenOrders:
    'Valitse kuljettaja alta. Kuljettajalle lähetetään valitun paketin kaikki tilaukset, joita ei ole vielä toimitettu.',
  CourierConfirmed: 'Kuljettaja vahvistettu',
  ChangeToSamedayOrder: 'Muuta pikatoimitukseksi',
  ConfirmChangeToSamedayOrder:
    'Vahvista valitun tilauksen muuttaminen pikatoimitukseksi',
  ConfirmSendToMyxline:
    'Vahvista valitun tilauksen lähettäminen MyXLinelle',
  CarType: 'Auton/kuljetuksen tyyppi',
  CarTypenormal: 'Henkilöauto',
  CarTypeflowerRoll: 'Pakettiauto (Kukkarullakko)',
  CarTypepallet: 'Pakettiauto',
  CarTypefinPallet: 'Paketti/minikuorma-auto (FIN-Lava)',
  CarTypeeurPallet: 'Paketti/minikuorma-auto (EUR-Lava)',
  ThisOrderCantBeTransportedInNormalCarWarning:
    'HUOM! Tämä tilaus vaatii vähintään pakettiauton. Varmista että kuski on liikkeellä pakettiautolla ennen kuin lähetät tämän tilauksen. Otathan myös huomioon, että pakettiautokuljetuksia ei voi lähettää kuskille jolla on tällä hetkellä jokin muu kuljetus meneillään',
  ThermoLoggerInfo: 'Loggerien tiedot',
  CurrentLogger: 'Nykyinen loggeri',
  PreviousLoggers: 'Edelliset loggerit',
  LoggerNo: 'Numero',
  EndTimestamp: 'Poistettu käytöstä',
  Originally: 'Alunperin',
  SetPickupTime: 'Muuta noutoaikaa',
  Today: 'Tänään',
  Time: 'Aika',
  ReleaseOrders: 'Vapauta tilaukset',
  ConfirmReleasePackage:
    'Haluatko varmasti vapauttaa kaikki paketin tilaukset? Otathan huomioon että tilaukset eivät saa olla vielä noudettuja',
  OrderSearchByProvider: 'Tilaushistoria',
  Immediately: 'HETI',
  ChooseMerchant: 'Valitse kauppiasyritys',
  WriteSearchDates: 'Syötä aikaväli',
  Merchant: 'Kauppiasyritys',
  Passed: 'Ei saatavilla',
  SpecialOrderSchedule: 'Kuljetusten aikataulu',
  OrdersThatAreUrgent: 'Kiireelliset toimitukset',
  LaterOrders: 'Myöhemmät toimitukset',
  CalendarView: 'Kalenterinäkymä',
  ListView: 'Listanäkymä',
  ChooseSpecialOrder: 'Valitse reitti',
  OrderAllocationMinutes:
    'Lähetä kuskille (minuuttia ennen noutoaikaa)',
  Locale: 'fi',
  AddOrder: 'Uusi keikka',
  AddOrderRetroactively: 'Merkitse ajettu keikka',
  FutureOrders: 'Tulevat keikat',
  PastOrders: 'Menneet keikat',
  MapLocation: 'Sijainti kartalla',
  StartAddressTitle: 'Noutopaikan nimi',
  StartAddress: 'Noutopaikan osoite',
  StartPostalCode: 'Noutopaikan postinumero',
  RecipientName: 'Vastaanottajan nimi',
  RecipientAddress: 'Vastaanottajan osoite',
  RecipientPostalCode: 'Vastaanottajan postinumero',
  MedicalTraining: 'Apteekkikoulutus',
  Logger: 'Loggeri',
  DeliveryConfirmedByPINCode: 'Toimitus varmistettu PIN-koodilla',
  PickupPhoto: 'Noudettaessa otettu kuva',
  MarkedAsDeliveredByAdmin: 'Merkattu ajetuksi Admin-paneelista',
  isFreelancer: 'Kuljettaja on Freelancer',
  Company: 'Yritys',
  SecondsAgo: 'Sekuntia sitten',
  LocationWhenDelivered: 'Sijainti toimitettaessa',
  ViaAddressPhotos: 'Väliosoitteiden kuvat',
  ViaAddresses: 'Väliosoitteet',
  Type: 'Tyyppi',
  Delivery: 'Toimitus',
  Pickup: 'Nouto',
  VisitedTimeStamp: 'Käyntiaika',
  Notes: 'Huomiot',
  RequiresCarType: 'Vaatii autotyypin',
  ColdDelivery: 'Kylmäkuljetus',
  UpdateNumPackages: 'Muokkaa pakettien määrää',
  Actions: 'Toiminnot',
  CourierArrivalTimestamp: 'Saapumisaika noutopaikalle',
  IsCriticallyUrgent: 'Kriittisesti kiireellinen',
  CancelledTimestamp: 'Peruttu',
  Country: 'Maa',
  SendToMyxline: 'Lähetä MyXLinelle',
  CantSendToMyxlineWithTemperatureMonitoringRequired:
    'Toistaiseksi apteekkikuljetuksia ei voi lähettää MyXLinelle',
  CantSendThisOrderToMyxline:
    'Valitettavasti tämän liikkeen lähetyksiä ei voi toistaiseksi lähettää MyXLinelle',
  MyxLineOrders: 'MyXLine-tilaukset',
  LatestDeliveryTimeMessage:
    'Aseta aika jolloin lähetyksen on oltava viimeistään perillä ja lähetä tilaus',
  LatestDeliveryTime: 'Viimeistään perillä',
  SendAllFromSameSender: 'Yhdistä saman lähettäjän tilaukset',
  LoggerReadingPhoto: 'Käytetty loggeri',
  OrdersWaitingToBeSentToMyxline:
    'Myxlinelle automaattisesti lähtevät lähetykset',
  OrdersWaitingToBeSentToMyxlineDescription:
    'Nämä lähetykset eivät vaadi toimenpiteitä, vaan automatiikka lähettää ne Myxlinelle. Voit kuitenkin tehdä muutoksia tarvittaessa ennen kuin ne lähtevät.',
};

export default fi;
