import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import Modal from 'lib/Modal';
import { TransportOrder } from '../orders/types';

import Gateway from '../web/gateway';

const range = (start: number, end: number): number[] => {
  const res: number[] = [];
  for (let i = start; i < end; i++) {
    res.push(i);
  }
  return res;
};

export const UpdateNumPackagesPopup = ({
  closePopup,
  closeParentPopup,
  transl,
  attrs,
}: {
  closePopup: () => any;
  closeParentPopup: () => any;
  transl: (label: string) => string;
  attrs: TransportOrder;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [numPackages, setNumPackages] = React.useState(
    attrs.numPackages
  );

  const saveNumPackages = (): void => {
    setLoading(true);
    Gateway.post(
      `/transportadmin/order/${attrs.transportOrderId}/set-num-packages`,
      { data: { numPackages } }
    )
      .then(closeParentPopup)
      .catch((err) => {
        alert('An error occurred: ' + err.toString());
      });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '400px',
      maxHeight: window.innerHeight - 100,
      width: '400px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'tyur'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('UpdateNumPackages')}</h2>
          <div className="form-group">
            <label>{transl('NumberOfPackages')}</label>
            <select
              className="form-control"
              value={numPackages.toString()}
              onChange={(e): void =>
                setNumPackages(parseInt(e.target.value, 10))
              }
            >
              {range(1, 51).map((item) => {
                return (
                  <option
                    key={item.toString()}
                    value={item.toString()}
                  >
                    {item.toString()}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveNumPackages}
            >
              {transl('Save')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
