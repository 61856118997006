import React from 'react';
import Select from 'react-virtualized-select';
import Gateway from '../web/gateway';

type Props = {
  onChange: (companyId: string | null) => void;
};

type SelectOption = {
  label: string;
  value: string;
};

export const ProviderCompanySelector = ({
  onChange,
}: Props): JSX.Element | null => {
  const [selectedOption, changeSelected] = React.useState(
    null as SelectOption | null
  );
  const [options, setOptions] = React.useState(
    null as SelectOption[] | null
  );
  React.useEffect(() => {
    Gateway.get('/transportadmin/providercompanies').then((res) => {
      setOptions(
        res.map((company) => ({
          value: company.businessID,
          label: company.name,
        }))
      );
    });
  }, []);
  const selectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    changeSelected(newSelection);
    onChange(newSelection ? newSelection.value : null);
  };
  if (options === null) {
    return null;
  }
  return (
    // @ts-ignore
    <Select
      options={options}
      onChange={selectionChanged}
      value={selectedOption}
    />
  );
};
