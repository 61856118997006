import * as base32 from 'hi-base32';

type DynamoKeys = {
  hashKey: string;
  rangeKey?: string;
};

export const dynamoKeysToBase32 = (
  hashKey: string,
  rangeKey?: string
): string => {
  const inputArray = [hashKey];
  if (rangeKey) {
    inputArray.push(rangeKey);
  }

  const jsonInput = JSON.stringify(inputArray);
  const base32Input = base32.encode(jsonInput);
  return base32Input.toLowerCase().split('=').join('1');
};

export const dynamoKeysFromBase32 = (
  input: string
): DynamoKeys | null => {
  try {
    const base32Input = input.toUpperCase().split('1').join('=');
    const jsonInput = base32.decode(base32Input);
    const parsedInput = JSON.parse(jsonInput);
    if (
      Array.isArray(parsedInput) &&
      parsedInput.length > 0 &&
      typeof parsedInput[0] === 'string'
    ) {
      if (
        parsedInput.length === 2 &&
        typeof parsedInput[1] === 'string'
      ) {
        return {
          hashKey: parsedInput[0],
          rangeKey: parsedInput[1],
        };
      }
      return {
        hashKey: parsedInput[0],
      };
    }
    return null;
  } catch (err) {
    return null;
  }
};

const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};
