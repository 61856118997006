import Gateway from '../web/gateway';
import { TransportOrder } from '../orders/types';

export type WeeklyScheduleItem = {
  courierBase32: string | null;
  orderCreationHour: number;
  orderCreationMinute: number;
  timeToPickupMinutes: number | null;
  timeUntilEarliestPickupTimeMinutes: number | null;
};

export type Weekday = 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su';

export const weekdays: Weekday[] = [
  'mo',
  'tu',
  'we',
  'th',
  'fr',
  'sa',
  'su',
];

export type SpecialOrderWeeklySchedule = {
  mo?: WeeklyScheduleItem[];
  tu?: WeeklyScheduleItem[];
  we?: WeeklyScheduleItem[];
  th?: WeeklyScheduleItem[];
  fr?: WeeklyScheduleItem[];
  sa?: WeeklyScheduleItem[];
  su?: WeeklyScheduleItem[];
  exceptionDates?: string[];
  timezone?: 'Europe/Helsinki';
};

export type ScheduleItem = {
  courierBase32: string | null;
  orderCreationTimestamp: string;
  timeToPickupMinutes: number | null;
  timeUntilEarliestPickupTimeMinutes: number | null;
};

export type SpecialOrder = {
  id: string;
  name: string;
  providerCompanyBusinessID: string | null;
  courierPrice: number;
  merchantPrice: number;
  courierSaturdayExtra: number;
  courierSundayExtra: number;
  merchantSundayExtra: number;
  linkedScheduledOrderID: string | null;
  orderCreateFields: {
    allowLeaveAtDoor: boolean;
    backupAddress: string | null;
    backupAddressPostalCode: string | null;
    backupPhone: string | null;
    city: string;
    externalID: string;
    numPackages: number;
    providerCompanyBusinessID: string;
    providerCompanyName: string;
    recipientAddress: string;
    recipientAddressNotes: string;
    recipientEmail: string;
    recipientName: string;
    recipientPhoneNo: string;
    recipientPostalCode: string;
    startAddress: string;
    startAddressNotes: string;
    startAddressPhone: string;
    startAddressTitle: string;
    startPostalCode: string;
  };
  weeklySchedule: SpecialOrderWeeklySchedule;
  weeklyTemplate: SpecialOrderWeeklySchedule;
  schedule: ScheduleItem[];
  returnToStartAfterComplete: boolean;
  deliveryConfirmationCanBeSkipped: boolean;
  requirePhotoAtStartAndEnd: boolean;
  priorityOrder: boolean;
  timezone: string;
};

export type TransportCourier = {
  companyBusinessID: string;
  email: string;
  name: string;
  bic: string | null;
  country: 'FI';
  currentLocationLat: number | null;
  currentLocationLong: number | null;
  currentRegisterPlate: string | null;
  currentTaxiNumber: string | null;
  currentVehicleModel: string | null;
  iban: string | null;
  isSubcontractor: boolean;
  language: 'fi' | 'en';
  phone: string | null;
  subcontractorCompanyBid: string | null;
  subcontractorCompanyName: string | null;
  subcontractorCompanyAddress: string | null;
  subcontractorCompanyPostalcode: string | null;
};

export const listCouriers = async (): Promise<TransportCourier[]> => {
  return await Gateway.get('/transportadmin/list-all-couriers');
};

export const listSpecialOrders = async (): Promise<
  SpecialOrder[]
> => {
  return await Gateway.get('/transportadmin/special-orders');
};

export const updateSchedule = async (
  specialOrderId: string,
  schedule: ScheduleItem[]
): Promise<void> => {
  await Gateway.put(
    `/transportadmin/special-orders/id/${specialOrderId}/schedule`,
    {
      data: {
        schedule,
      },
    }
  );
};

export const createOrderRetroactively = async (
  specialOrderId: string,
  courierBase32: string,
  externalID: string,
  startTimestamp: string,
  endTimestamp: string
): Promise<void> => {
  await Gateway.put(
    `/transportadmin/special-orders/id/${specialOrderId}/create-retroactively`,
    {
      data: {
        courierBase32,
        externalID,
        startTimestamp,
        endTimestamp,
      },
    }
  );
};

export const specialOrderHistory = async (
  specialOrderId: string,
  startDate: string,
  endDate: string
): Promise<TransportOrder[]> => {
  return await Gateway.get(
    `/transportadmin/special-orders/id/${specialOrderId}/history?start=${startDate}&end=${endDate}`
  );
};
