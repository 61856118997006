import React from 'react';
import { getDistanceFromLatLonInKm } from 'lib/helpers';
import Spinner from 'lib/Spinner';
import moment from 'lib/moment';
import _ from 'lodash';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import {
  fetchAllUnallocatedOrders,
  createTransportOrderPackage,
  changeToSamehourOrder,
  fetchAllCouriersOnline,
  fetchAllOrderPackages,
} from './requests';
import { TransportOrder } from '../orders/types';
import { ChooseCourierComponent } from '../ChooseCourierComponent';
import { CancelOrderPopup } from '../monitoring/CancelOrderPopup';
import { UnallocatedOrdersView as UnallocatedUrgentOrdersView } from '../monitoring/UnallocatedOrdersView';
import { CourierOnlineData } from '../monitoring/types';
import { SetPickupTimePopup } from '../monitoring/SetPickupTimePopup';
import { SamedayOrderPackage } from './types';
import { SendToMyxlinePopup } from './SendToMyxlinePopup';

const formatTimestamp = (stamp: string): string =>
  moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm');

const formatAddress = (
  title: string,
  shortAddress: string,
  postalCode: string
): string => `${title} (${shortAddress}, ${postalCode})`;

const lessThanAnHourToPickup = (o: TransportOrder) => {
  if (!o.earliestPickupTime) return true;
  const hourFromNow = moment().add(60, 'minutes');
  if (moment(o.earliestPickupTime).isAfter(hourFromNow)) {
    return false;
  }
  return true;
};

const isMyxlineOrder = (o: TransportOrder) => {
  return !!o.myXLineId;
};

const isOrderInMyxlineCity = (o: TransportOrder) => {
  return !!o.isMyxlineCity;
};

const getPickupPlaceStringForOrder = (order: TransportOrder) =>
  `${order.providerCompanyBusinessID}${order.startAddressTitle}${order.startAddress}${order.startPostalCode}`
    .split(' ')
    .join('');

const ListItem = ({
  attrs,
  toggleSelection,
  isSelected,
  openCancelPopup,
  transl,
  changeToSamehour,
  hideButtons,
  openSetPickupTimePopup,
  sendToMyxline,
}: {
  attrs: TransportOrder;
  toggleSelection: (order: TransportOrder) => void;
  isSelected: boolean;
  key: any;
  openCancelPopup: () => any;
  changeToSamehour: () => any;
  sendToMyxline: () => any;
  transl: (label: string) => string;
  hideButtons?: boolean;
  openSetPickupTimePopup: () => any;
}): JSX.Element => (
  <tr
    onClick={(): void => toggleSelection(attrs)}
    style={{ cursor: hideButtons ? 'auto' : 'pointer' }}
  >
    <td>
      {!hideButtons && <input type="checkbox" checked={isSelected} />}
    </td>
    <td>
      {attrs.metaData?.packageType !== 'normal' && (
        <span style={{ fontWeight: 'bold', color: 'red' }}>
          {transl('RequiresCarType')}:{' '}
          {transl('CarType' + (attrs.metaData?.packageType || ''))}
          <br />
        </span>
      )}
      {attrs.isColdDelivery && (
        <span style={{ fontWeight: 'bold', color: 'blue' }}>
          {transl('ColdDelivery')}
          <br />
        </span>
      )}
      {attrs.numPackages > 1 && (
        <span style={{ fontWeight: 'bold', color: 'black' }}>
          {transl('NumberOfPackages')}: {attrs.numPackages}
          <br />
        </span>
      )}
      {attrs.isCriticallyUrgent && (
        <span style={{ fontWeight: 'bold', color: 'red' }}>
          {transl('IsCriticallyUrgent')}
          <br />
        </span>
      )}
    </td>
    <td>{attrs.city}</td>
    <td>
      {attrs.providerCompanyName} ({attrs.providerCompanyBusinessID})
    </td>
    <td>{formatTimestamp(attrs.orderPlacedTimestamp as string)}</td>
    <td>
      {!!attrs.earliestPickupTime
        ? formatTimestamp(attrs.earliestPickupTime)
        : '-'}
    </td>
    <td>
      {formatAddress(
        attrs.startAddressTitle,
        attrs.startAddress,
        attrs.startPostalCode
      )}
    </td>
    <td>{attrs.startAddressNotes}</td>
    <td>
      {formatAddress(
        attrs.recipientName,
        attrs.recipientAddress,
        attrs.recipientPostalCode
      )}
    </td>
    <td>{attrs.recipientAddressNotes}</td>
    <td>{attrs.distanceInKm.toFixed(3)}</td>
    <td>
      {!hideButtons && (
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={changeToSamehour}
        >
          {transl('ChangeToSamedayOrder')}
        </button>
      )}
      {hideButtons && (
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={openSetPickupTimePopup}
        >
          {transl('SetPickupTime')}
        </button>
      )}
    </td>
    <td>
      {!hideButtons && (
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={sendToMyxline}
        >
          {transl('SendToMyxline')}
        </button>
      )}
    </td>
    <td>
      {!hideButtons && (
        <button
          type="button"
          className="btn btn-danger btn-md"
          onClick={openCancelPopup}
        >
          {transl('CancelOrder')}
        </button>
      )}
    </td>
  </tr>
);

const ListHeader = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => (
  <thead>
    <tr>
      <th>{transl('Selected')}</th>
      <th>{transl('Notes')}</th>
      <th>{transl('City')}</th>
      <th>{transl('Sender')}</th>
      <th>{transl('OrderPlacedTimestamp')}</th>
      <th>{transl('PickupTime')}</th>
      <th>{transl('PickupAddress')}</th>
      <th>{transl('PickupInstructions')}</th>
      <th>{transl('RecipientAddress')}</th>
      <th>{transl('DeliveryInstructions')}</th>
      <th>{transl('DistanceinKm')}</th>
      <th>{transl('ChangeToSamedayOrder')}</th>
      <th>{transl('SendToMyxline')}</th>
      <th>{transl('CancelOrder')}</th>
    </tr>
  </thead>
);

export const UnallocatedOrdersView = ({
  transl,
  setChosenView,
}: {
  transl: (label: string) => string;
  setChosenView: (
    view: 'unallocatedOrders' | 'currentOrderPackages'
  ) => any;
}): JSX.Element => {
  const [orders, setOrders] = React.useState(
    null as TransportOrder[] | null
  );
  const [orderPackages, setOrderPackages] = React.useState(
    [] as SamedayOrderPackage[]
  );

  const [selectedOrders, setSelectedOrders] = React.useState<
    TransportOrder[]
  >([]);
  const [courierBase32, setCourierBase32] = React.useState<
    string | null
  >(null);
  const [spinnerVisible, setSpinnerVisible] =
    React.useState<boolean>(false);
  const [cancelOrderPopupData, setCancelOrderPopupData] =
    React.useState(null as TransportOrder | null);
  const [couriersOnline, setCouriersOnline] = React.useState(
    null as CourierOnlineData[] | null
  );
  const [setPickupTimePopupData, setSetPickupTimePopupData] =
    React.useState(null as TransportOrder | null);
  const [sendToMyxlinePopupData, setSendToMyxlinePopupData] =
    React.useState(null as TransportOrder | null);

  React.useEffect(() => {
    fetchAllUnallocatedOrders().then((data) => setOrders(data));
    fetchAllCouriersOnline().then((data) => setCouriersOnline(data));
    fetchAllOrderPackages().then((data) => setOrderPackages(data));
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const refresh = React.useCallback(
    async (showLoading = true): Promise<void> => {
      if (showLoading) {
        setOrders(null);
        setCouriersOnline(null);
      }
      const [orders, couriers] = await Promise.all([
        fetchAllUnallocatedOrders(),
        fetchAllCouriersOnline(),
      ]);
      setOrders(orders);
      setCouriersOnline(couriers);
    },
    [setOrders, setCouriersOnline]
  );

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      refresh(false);
    }, 30000);
    return (): void => {
      window.clearInterval(interval);
    };
  }, [refresh]);

  const changeToSamehour = async (
    transportOrderId: string
  ): Promise<void> => {
    if (!window.confirm(transl('ConfirmChangeToSamedayOrder'))) {
      return;
    }
    setOrders(null);
    setCouriersOnline(null);
    await changeToSamehourOrder(transportOrderId);
    await refresh();
  };

  const toggleSelection = (order: TransportOrder): void => {
    if (
      order.earliestPickupTime &&
      moment(order.earliestPickupTime)
        .subtract(1, 'hour')
        .isAfter(moment())
    ) {
      return;
    }
    if (
      selectedOrders.find(
        (item) => item.transportOrderId === order.transportOrderId
      )
    ) {
      setSelectedOrders(
        selectedOrders.filter(
          (item) => item.transportOrderId !== order.transportOrderId
        )
      );
    } else {
      if (
        selectedOrders.length > 0 &&
        getDistanceFromLatLonInKm(
          order.startLat,
          order.startLong,
          selectedOrders[0].startLat,
          selectedOrders[0].startLong
        ) > 50
      ) {
        return;
      }
      const pickupPlaceString = getPickupPlaceStringForOrder(order);
      const ordersToSelect = (orders || []).filter((item) => {
        if (
          item.earliestPickupTime &&
          moment(item.earliestPickupTime)
            .subtract(1, 'hour')
            .isAfter(moment())
        ) {
          return false;
        }
        return (
          pickupPlaceString === getPickupPlaceStringForOrder(item)
        );
      });
      setSelectedOrders(
        _.uniqBy(
          [...selectedOrders, ...ordersToSelect],
          (item) => item.transportOrderId
        )
      );
    }
  };

  const createOrderPackage = (): void => {
    if (!window.confirm(transl('ConfirmCreateOrderPackage'))) return;
    if (selectedOrders.length === 0 || !courierBase32) return;
    setSpinnerVisible(true);
    createTransportOrderPackage(
      selectedOrders.map((item) => item.transportOrderId),
      courierBase32
    )
      .then(() => {
        setChosenView('currentOrderPackages');
      })
      .catch((err) => {
        setSpinnerVisible(false);
        window.alert(err.toString());
      });
  };

  const closeCancelOrderPopup = (): void => {
    setCancelOrderPopupData(null);
    refresh();
  };

  const closeSetPickupTimePopup = (): void => {
    setSetPickupTimePopupData(null);
    refresh();
  };

  const closeSendToMyxlinePopup = (): void => {
    setSendToMyxlinePopupData(null);
    refresh();
  };

  if (orders === null || couriersOnline === null) {
    return <LoadingComponent />;
  }

  const allocatedSamedayOrdersIds = orderPackages.reduce(
    (res, val) => ({
      ...res,
      ...val.orders.reduce(
        (res1, val1) => ({ ...res1, [val1]: true }),
        {} as { [transportOrderID: string]: boolean }
      ),
    }),
    {} as { [transportOrderID: string]: boolean }
  );

  const samedayOrders = orders.filter(
    (o) =>
      o.serviceLevel === 'sameday' &&
      lessThanAnHourToPickup(o) &&
      !isMyxlineOrder(o) &&
      !isOrderInMyxlineCity(o) &&
      !allocatedSamedayOrdersIds[o.transportOrderId]
  );
  const urgentOrders = orders.filter(
    (o) =>
      o.serviceLevel === 'samehour' &&
      lessThanAnHourToPickup(o) &&
      !isMyxlineOrder(o) &&
      !isOrderInMyxlineCity(o) &&
      !allocatedSamedayOrdersIds[o.transportOrderId]
  );
  const laterOrders = orders.filter(
    (o) =>
      !lessThanAnHourToPickup(o) &&
      !isMyxlineOrder(o) &&
      !isOrderInMyxlineCity(o) &&
      !allocatedSamedayOrdersIds[o.transportOrderId]
  );
  const myxlineCityOrders = orders.filter(
    (o) =>
      isOrderInMyxlineCity(o) &&
      !isMyxlineOrder(o) &&
      !allocatedSamedayOrdersIds[o.transportOrderId]
  );
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div>
        <div
          className="pull-left"
          style={{
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '25px',
          }}
        >
          <button
            type="button"
            className="btn btn-success btn-md"
            onClick={refresh}
          >
            <i className="fa fa-refresh" aria-hidden="true"></i>
            &nbsp;{transl('Refresh')}
          </button>
        </div>
      </div>
      {urgentOrders.length > 0 && (
        <UnallocatedUrgentOrdersView
          transl={transl}
          orders={urgentOrders}
          couriersOnline={couriersOnline}
          refresh={refresh}
          urgent
        />
      )}
      <div
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
          paddingTop: '25px',
        }}
      >
        <div className="pull-left" style={{ width: '400px' }}>
          <ChooseCourierComponent
            transl={transl}
            selectedCourierBase32Changed={setCourierBase32}
          />
        </div>
        <div
          className="pull-left"
          style={{ paddingLeft: '15px', paddingTop: '25px' }}
        >
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={createOrderPackage}
            disabled={selectedOrders.length === 0 || !courierBase32}
          >
            &nbsp;{transl('SendSelectedToCourier')}
          </button>
        </div>
      </div>
      <div style={commonStyles.defaultContainer}>
        <table className="table table-striped table-hover">
          <ListHeader transl={transl} />
          <tbody>
            {samedayOrders.map((attrs, i) => {
              return (
                <ListItem
                  key={i}
                  toggleSelection={toggleSelection}
                  attrs={attrs}
                  transl={transl}
                  isSelected={
                    !!selectedOrders.find(
                      (item) =>
                        item.transportOrderId ===
                        attrs.transportOrderId
                    )
                  }
                  openCancelPopup={(): void =>
                    setCancelOrderPopupData(attrs)
                  }
                  changeToSamehour={(): any =>
                    changeToSamehour(attrs.transportOrderId)
                  }
                  sendToMyxline={(): any =>
                    setSendToMyxlinePopupData(attrs)
                  }
                  openSetPickupTimePopup={(): void =>
                    setSetPickupTimePopupData(attrs)
                  }
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={commonStyles.defaultContainer}>
        <h3>{transl('LaterOrders')}</h3>
        <table className="table table-striped table-hover">
          <ListHeader transl={transl} />
          <tbody>
            {laterOrders.map((attrs, i) => {
              return (
                <ListItem
                  key={i}
                  toggleSelection={() => {
                    // Intentionally empty
                  }}
                  attrs={attrs}
                  transl={transl}
                  isSelected={false}
                  openCancelPopup={(): void => {
                    // Intentionally empty
                  }}
                  changeToSamehour={(): any => {
                    // Intentionally empty
                  }}
                  hideButtons={true}
                  openSetPickupTimePopup={(): void =>
                    setSetPickupTimePopupData(attrs)
                  }
                  sendToMyxline={(): any => {
                    // Intentionally empty
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={commonStyles.defaultContainer}>
        <h3>{transl('OrdersWaitingToBeSentToMyxline')}</h3>
        <div>
          {transl('OrdersWaitingToBeSentToMyxlineDescription')}
        </div>
        <table className="table table-striped table-hover">
          <ListHeader transl={transl} />
          <tbody>
            {myxlineCityOrders.map((attrs, i) => {
              return (
                <ListItem
                  key={i}
                  toggleSelection={toggleSelection}
                  attrs={attrs}
                  transl={transl}
                  isSelected={
                    !!selectedOrders.find(
                      (item) =>
                        item.transportOrderId ===
                        attrs.transportOrderId
                    )
                  }
                  openCancelPopup={(): void =>
                    setCancelOrderPopupData(attrs)
                  }
                  changeToSamehour={(): any =>
                    changeToSamehour(attrs.transportOrderId)
                  }
                  sendToMyxline={(): any =>
                    setSendToMyxlinePopupData(attrs)
                  }
                  openSetPickupTimePopup={(): void =>
                    setSetPickupTimePopupData(attrs)
                  }
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Spinner visible={spinnerVisible} />
      {cancelOrderPopupData !== null && (
        <CancelOrderPopup
          closePopup={closeCancelOrderPopup}
          closeParentPopup={closeCancelOrderPopup}
          transl={transl}
          attrs={cancelOrderPopupData}
        />
      )}
      {setPickupTimePopupData !== null && (
        <SetPickupTimePopup
          closePopup={closeSetPickupTimePopup}
          closeParentPopup={closeSetPickupTimePopup}
          transl={transl}
          attrs={setPickupTimePopupData}
        />
      )}
      {sendToMyxlinePopupData !== null && (
        <SendToMyxlinePopup
          closePopup={closeSendToMyxlinePopup}
          transl={transl}
          attrs={sendToMyxlinePopupData}
        />
      )}
    </div>
  );
};
