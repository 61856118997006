import React from 'react';
import Header from './Header';
import { commonStyles } from 'lib/commonStyles';
import { Notifier } from 'lib/Notifier';
import SpinnerContainer from 'lib/SpinnerContainer';
import TransportCouriersView from './transportCouriers/TransportCouriersView';
import PayperiodView from './payperiods/PayperiodView';
import { MonitoringView } from './monitoring/MonitoringView';
import { LoadingComponent } from './lib/LoadingComponent';
import { SamedayOrdersView } from './samedayOrders/SamedayOrdersView';
import { OrderSearchView } from './orders/OrderSearchView';
import { SpecialOrderScheduleView } from './specialOrderSchedule/SpecialOrderScheduleView';

import Gateway from './web/gateway';

type TransportCompany = {
  businessID: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: 'FI';
  language: 'fi' | 'en';
  iban: string;
  bic: string;
  isMainTransportCompany: boolean;
  contactPersonEmail: string;
  shouldSkipAutomatedPayments: boolean;
};

const MainView = ({
  transl,
  companyName,
}: {
  transl: (label: string) => string;
  companyName: string | null;
}): JSX.Element => {
  const [chosenView, setChosenView] = React.useState('');
  const [companyData, setCompanyData] = React.useState(
    null as TransportCompany | null
  );

  React.useEffect(() => {
    Gateway.get(`/transportadmin/companysettings`).then(
      (data: TransportCompany) => {
        setCompanyData(data);
        setChosenView(
          data.isMainTransportCompany ? 'monitoring' : 'orderHistory'
        );
      }
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  if (!companyData) return <LoadingComponent />;

  return (
    <Notifier>
      <div style={commonStyles.flexColumnContainer}>
        <Header transl={transl} companyName={companyName} />
        <div
          style={{
            width: '100%',
            display: 'block',
          }}
        >
          <div className="btn-group btn-group-justified">
            {companyData.isMainTransportCompany && (
              <span
                style={{ borderRadius: '0px' }}
                onClick={(): void => setChosenView('monitoring')}
                className={
                  'btn btn-primary' +
                  (chosenView === 'monitoring' ? ' active' : '')
                }
              >
                {transl('Monitoring')}
              </span>
            )}
            {companyData.isMainTransportCompany && (
              <span
                style={{ borderRadius: '0px' }}
                onClick={(): void => setChosenView('samedayOrders')}
                className={
                  'btn btn-primary' +
                  (chosenView === 'samedayOrders' ? ' active' : '')
                }
              >
                {transl('SamedayOrders')}
              </span>
            )}
            {companyData.isMainTransportCompany && (
              <span
                style={{ borderRadius: '0px' }}
                onClick={(): void => setChosenView('orderSearch')}
                className={
                  'btn btn-primary' +
                  (chosenView === 'orderSearch' ? ' active' : '')
                }
              >
                {transl('OrderSearchByProvider')}
              </span>
            )}
            <span
              style={{ borderRadius: '0px' }}
              onClick={(): void => setChosenView('orderHistory')}
              className={
                'btn btn-primary' +
                (chosenView === 'orderHistory' ? ' active' : '')
              }
            >
              {transl('OrdersByCourier')}
            </span>
            {companyData.isMainTransportCompany && (
              <span
                style={{ borderRadius: '0px' }}
                onClick={(): void =>
                  setChosenView('specialOrderSchedule')
                }
                className={
                  'btn btn-primary' +
                  (chosenView === 'specialOrderSchedule'
                    ? ' active'
                    : '')
                }
              >
                {transl('SpecialOrderSchedule')}
              </span>
            )}
            <span
              style={{ borderRadius: '0px' }}
              onClick={(): void => setChosenView('couriers')}
              className={
                'btn btn-primary' +
                (chosenView === 'couriers' ? ' active' : '')
              }
            >
              {transl('Couriers')}
            </span>
          </div>
        </div>
        <div style={commonStyles.flexColumnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            {chosenView === 'monitoring' &&
              companyData.isMainTransportCompany && (
                <MonitoringView transl={transl} />
              )}
            {chosenView === 'orderSearch' &&
              companyData.isMainTransportCompany && (
                <OrderSearchView transl={transl} />
              )}
            {chosenView === 'orderHistory' && (
              <PayperiodView
                isMainTransportCompany={
                  companyData.isMainTransportCompany
                }
                transl={transl}
              />
            )}
            {chosenView === 'couriers' && (
              <TransportCouriersView
                isMainTransportCompany={
                  companyData.isMainTransportCompany
                }
                transl={transl}
              />
            )}
            {chosenView === 'samedayOrders' &&
              companyData.isMainTransportCompany && (
                <SamedayOrdersView transl={transl} />
              )}
            {chosenView === 'specialOrderSchedule' &&
              companyData.isMainTransportCompany && (
                <SpecialOrderScheduleView transl={transl} />
              )}
          </div>
        </div>
      </div>
      <SpinnerContainer />
    </Notifier>
  );
};

export default MainView;
