import { makeRequestWithApiToken as makeRequest } from './tokens';

const Gateway = {
  get: (url: string): Promise<any> => {
    return makeRequest('GET', url);
  },

  post: (url: string, data: any): Promise<any> => {
    return makeRequest('POST', url, data);
  },

  put: (url: string, data: any): Promise<any> => {
    return makeRequest('PUT', url, data);
  },

  patch: (url: string, data: any): Promise<any> => {
    return makeRequest('PATCH', url, data);
  },

  del: (url: string): Promise<any> => {
    return makeRequest('DELETE', url);
  }
};

export default Gateway;
