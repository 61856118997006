/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import moment from 'moment-timezone';
import { ChooseCourierComponent } from '../ChooseCourierComponent';
import DatePicker from 'lib/DatePicker';

const generateUniqueExternalID = (): string => {
  return moment().format('YYYYMMDD-HHmmss-SSS');
};

export const AddScheduleItemRetroactivelyPopup = ({
  closePopup,
  transl,
  addScheduleItemRetroactively,
  timezone,
}: {
  closePopup: () => any;
  transl: (label: string) => string;
  addScheduleItemRetroactively: (
    courierBase32: string,
    externalID: string,
    startTimestamp: string,
    endTimestamp: string
  ) => Promise<void>;
  timezone: string;
}): JSX.Element => {
  const [courierBase32, setCourierBase32] = React.useState<
    string | null
  >(null);

  const [startTimestamp, setStartTimestamp] = React.useState(
    moment().locale(transl('Locale')).tz(timezone).toISOString()
  );

  const [endTimestamp, setEndTimestamp] = React.useState(
    moment().locale(transl('Locale')).tz(timezone).toISOString()
  );

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  const saveData = () => {
    if (!courierBase32) return;

    addScheduleItemRetroactively(
      courierBase32,
      generateUniqueExternalID(),
      startTimestamp,
      endTimestamp
    );
    closePopup();
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('AddOrderRetroactively')}</h2>
          <ChooseCourierComponent
            transl={transl}
            selectedCourierBase32Changed={setCourierBase32}
          />
          <div className="form-group">
            <label htmlFor="addScheduleItemPopup-date">
              {transl('Date')}
            </label>
            <DatePicker
              selected={moment(startTimestamp)
                .locale(transl('Locale'))
                .tz(timezone)
                .toDate()}
              className="form-control"
              onChange={(val): void => {
                if (val) {
                  setStartTimestamp(
                    moment(val)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .set({
                        hour: moment(startTimestamp)
                          .locale(transl('Locale'))
                          .tz(timezone)
                          .hour(),
                        minute: moment(startTimestamp)
                          .locale(transl('Locale'))
                          .tz(timezone)
                          .minute(),
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString()
                  );
                  setEndTimestamp(
                    moment(val)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .set({
                        hour: moment(endTimestamp)
                          .locale(transl('Locale'))
                          .tz(timezone)
                          .hour(),
                        minute: moment(endTimestamp)
                          .locale(transl('Locale'))
                          .tz(timezone)
                          .minute(),
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString()
                  );
                }
              }}
              dateFormat={'dd.MM.yyyy'}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addScheduleItemPopup-date">
              {transl('PickupTime')}
            </label>
            <input
              type="text"
              value={
                moment(startTimestamp)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .hour() > 0
                  ? moment(startTimestamp)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .hour()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let hour = parseInt(e.target.value, 10) || 0;
                if (hour < 0 || hour > 23) hour = 0;
                setStartTimestamp(
                  moment(startTimestamp)
                    .locale(transl('Locale'))
                    .tz(timezone)
                    .set({
                      hour,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />{' '}
            :{' '}
            <input
              type="text"
              value={
                moment(startTimestamp)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .minute() > 0
                  ? moment(startTimestamp)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .minute()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let minute = parseInt(e.target.value, 10) || 0;
                if (minute < 0 || minute > 59) minute = 0;
                setStartTimestamp(
                  moment(startTimestamp)
                    .locale(transl('Locale'))
                    .tz(timezone)
                    .set({
                      minute,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="addScheduleItemPopup-date">
              {transl('DeliveryTimestamp')}
            </label>
            <input
              type="text"
              value={
                moment(endTimestamp)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .hour() > 0
                  ? moment(endTimestamp)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .hour()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let hour = parseInt(e.target.value, 10) || 0;
                if (hour < 0 || hour > 23) hour = 0;
                setEndTimestamp(
                  moment(endTimestamp)
                    .locale(transl('Locale'))
                    .tz(timezone)
                    .set({
                      hour,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />{' '}
            :{' '}
            <input
              type="text"
              value={
                moment(endTimestamp)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .minute() > 0
                  ? moment(endTimestamp)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .minute()
                      .toString()
                      .padStart(2, '0')
                  : ''
              }
              placeholder="00"
              className="form-control"
              onChange={(e): void => {
                let minute = parseInt(e.target.value, 10) || 0;
                if (minute < 0 || minute > 59) minute = 0;
                setEndTimestamp(
                  moment(endTimestamp)
                    .locale(transl('Locale'))
                    .tz(timezone)
                    .set({
                      minute,
                      second: 0,
                      millisecond: 0,
                    })
                    .toISOString()
                );
              }}
              style={{ width: '100px', display: 'inline' }}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={!courierBase32}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Confirm')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
