import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import {
  fetchAllOrderPackages,
  sendUnsentPackagesToCouriers,
  releaseTransportOrderPackage,
} from './requests';
import { SamedayOrderPackage } from './types';
import { IS_TEST_SERVER } from '../web/constants';
import { ReallocateOrderPackagePopup } from './ReallocateOrderPackagePopup';

const getStateLabelForPackage = (
  attrs: SamedayOrderPackage,
  transl: (label: string) => string
): string => {
  if (
    attrs.routeStr &&
    attrs.sentToCourier &&
    attrs.courierConfirmed
  ) {
    return transl('CourierConfirmed');
  }
  if (attrs.routeStr && attrs.sentToCourier) {
    return transl('SentToCourier');
  }
  if (attrs.routeStr) {
    return transl('WaitingToBeSentToCourier');
  }
  return transl('RouteCalculationInProgress');
};

type ListItemProps = {
  attrs: SamedayOrderPackage;
  transl: (label: string) => string;
  openReallocatePopup: () => any;
  releasePackage: () => any;
  key: any;
};

const ListItem = ({
  attrs,
  transl,
  openReallocatePopup,
  releasePackage,
}: ListItemProps): JSX.Element => (
  <tr>
    <td>{getStateLabelForPackage(attrs, transl)}</td>
    <td>
      {attrs.courier.name} ({attrs.courier.email})
    </td>
    <td>{attrs.orders.length}</td>
    <td>
      <button
        type="button"
        className="btn btn-primary btn-md"
        onClick={openReallocatePopup}
      >
        {transl('ChangeCourier')}
      </button>
    </td>
    <td>
      <button
        type="button"
        className="btn btn-primary btn-md"
        onClick={releasePackage}
      >
        {transl('ReleaseOrders')}
      </button>
    </td>
  </tr>
);

export const OrderPackagesView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [orderPackages, setOrderPackages] = React.useState(
    null as SamedayOrderPackage[] | null
  );
  const [
    reallocatePackageAttrs,
    setReallocatePackageAttrs,
  ] = React.useState(null as SamedayOrderPackage | null);

  React.useEffect(() => {
    fetchAllOrderPackages().then((data) => setOrderPackages(data));
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const refresh = async (): Promise<void> => {
    setOrderPackages(null);
    const data = await fetchAllOrderPackages();
    if (IS_TEST_SERVER) {
      await sendUnsentPackagesToCouriers();
    }
    setOrderPackages(data);
  };

  const releasePackage = async (id: string): Promise<void> => {
    if (!window.confirm(transl('ConfirmReleasePackage'))) return;
    setOrderPackages(null);
    await releaseTransportOrderPackage(id);
    refresh();
  };

  if (orderPackages === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-md"
          onClick={refresh}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
          &nbsp;{transl('Refresh')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('State')}</th>
              <th>{transl('Courier')}</th>
              <th>{transl('Orders')}</th>
              <th>{transl('ChangeCourier')}</th>
              <th>{transl('ReleaseOrders')}</th>
            </tr>
          }
        >
          {orderPackages.map((attrs, i) => {
            return (
              <ListItem
                key={i}
                transl={transl}
                attrs={attrs}
                openReallocatePopup={(): void =>
                  setReallocatePackageAttrs(attrs)
                }
                releasePackage={() => releasePackage(attrs.id)}
              />
            );
          })}
        </FixedTable>
      </div>
      {reallocatePackageAttrs && (
        <ReallocateOrderPackagePopup
          attrs={reallocatePackageAttrs}
          transl={transl}
          closePopup={(): void => {
            setReallocatePackageAttrs(null);
            refresh();
          }}
        />
      )}
    </div>
  );
};
