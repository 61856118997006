import React from 'react';
import Colors from './colors';

import { commonStyles } from './commonStyles';

type State = {
  visible: boolean;
  textContent: string;
  textStyle: any;
};

class SpinnerContainer extends React.Component<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      textContent: '',
      textStyle: {},
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.propsChanged = this.propsChanged.bind(this);
  }

  propsChanged = (
    visible: boolean,
    textContent = '',
    textStyle: any = {}
  ): void => {
    if (!visible) {
      this.setState({
        visible: false,
        textContent: '',
        textStyle: {},
      });
    } else {
      this.setState({
        visible,
        textContent,
        textStyle,
      });
    }
  };

  componentDidMount(): void {
    // @ts-ignore
    window.ajaxSpinner = this;
  }

  render(): JSX.Element | null {
    const visible = this.state.visible;
    const textContent = this.state.textContent;
    const textStyle = this.state.textStyle;

    if (!visible) {
      return null;
    }
    const txtStyle = textStyle || {};

    return (
      <div>
        <div
          style={Object.assign(
            {},
            commonStyles.fixedContainer,
            styles.overlay
          )}
        >
          <div
            style={Object.assign(
              {},
              commonStyles.fixedContainer,
              styles.main
            )}
          >
            <div
              style={Object.assign(
                {},
                commonStyles.flexColumnContainer,
                commonStyles.flexCenter
              )}
            >
              <i
                style={styles.spinner}
                className="fa fa-spinner fa-spin fa-3x fa-fw"
              ></i>
              <span style={txtStyle}>{textContent}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  overlay: {
    backgroundColor: Colors.main,
    opacity: '0.5',
    zIndex: 10000000,
    display: 'flex',
  },
  main: {
    zIndex: 10000001,
    display: 'flex',
  },
  spinner: {
    color: 'white',
  },
};

export default SpinnerContainer;
