import React from 'react';
import FixedTable from 'lib/FixedTable';
import { dynamoKeysToBase32 } from 'lib/helpers';
import { LoadingComponent } from 'lib/LoadingComponent';
import { DateSearchBox } from 'lib/DateSearchBox';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';
import { ProviderCompanySelector } from './ProviderCompanySelector';
import { ChangeCourierPopup } from './ChangeCourierPopup';
import { TransportCourier } from '../transportCouriers/types';
import { OrderDetailsPopup } from './OrderDetailsPopup';
import { CreateRetroactiveOrderPopup } from './CreateRetroactiveOrderPopup';
import { UpdateNumPackagesPopup } from '../monitoring/UpdateNumPackagesPopup';

import Gateway from '../web/gateway';

const formatTimestamp = (stamp: string): string =>
  moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm');

const formatAddress = (
  title: string,
  shortAddress: string,
  postalCode: string
): string => `${title} (${shortAddress}, ${postalCode})`;

type HistoryListItemProps = {
  attrs: any;
  key: any;
  openCourierChangePopup: () => any;
  openDetailsPopup: () => any;
  openUpdateNumPackagesPopup: () => any;
  transl: (label: string) => string;
  courier?: TransportCourier;
};

const HistoryListItem = ({
  transl,
  attrs,
  openCourierChangePopup,
  courier,
  openDetailsPopup,
  openUpdateNumPackagesPopup,
}: HistoryListItemProps): JSX.Element => {
  return (
    <tr style={{ cursor: 'pointer' }} onClick={openDetailsPopup}>
      <td>{attrs.externalID}</td>
      <td>{transl(attrs.state)}</td>
      <td>
        {attrs.distanceInKm.toFixed(3)} (
        {attrs.actualDistanceInKm.toFixed(3)})
      </td>
      <td>{formatTimestamp(attrs.orderPlacedTimestamp)}</td>
      <td>
        {formatAddress(
          attrs.startAddressTitle,
          attrs.startAddress,
          attrs.startPostalCode
        )}
        <br />
        {attrs.startAddressPhone}
      </td>
      <td>
        {formatAddress(
          attrs.recipientName,
          attrs.recipientAddress,
          attrs.recipientPostalCode
        )}
        <br />
        {attrs.recipientPhoneNo}
      </td>
      <td>
        {!!attrs.earliestPickupTime
          ? formatTimestamp(attrs.earliestPickupTime)
          : transl('Immediately')}
      </td>
      <td>
        {courier
          ? `${courier.name} (${courier.email})`
          : attrs.courierEmailAndCompany}
      </td>
      <td>
        <button
          className="btn btn-primary btn-md"
          onClick={(e): void => {
            e.stopPropagation();
            e.preventDefault();
            openCourierChangePopup();
          }}
          disabled={
            ['orderPlaced', 'courierChosen', 'pickedUp'].includes(
              attrs.state
            ) || !!attrs.routeReferenceOrderId
          }
        >
          {transl('ChangeCourier')}
        </button>
      </td>
      <td>
        <button
          className="btn btn-primary btn-md"
          onClick={(e): void => {
            e.stopPropagation();
            e.preventDefault();
            openUpdateNumPackagesPopup();
          }}
        >
          {transl('UpdateNumPackages')}
        </button>
      </td>
    </tr>
  );
};

const courierToCourierKey = (courier: TransportCourier): string =>
  courier.email + '--' + courier.companyBusinessID;

export const OrderSearchView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [providerCompanyBusinessID, setProviderCompanyBusinessID] =
    React.useState(null as string | null);

  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [orders, setOrders] = React.useState([] as any[] | null);

  const [chosenOrderKeyBase32, setChosenOrderKeyBase32] =
    React.useState(null as string | null);

  const [changeCourierPopupData, setChangeCourierPopupData] =
    React.useState(null as any);
  const [
    updateNumPackagesPopupAttrs,
    setUpdateNumPackagesPopupAttrs,
  ] = React.useState(null as any);

  const [
    retroactiveOrderCreationPopupOpen,
    setRetroactiveOrderCreationPopupOpen,
  ] = React.useState(false);

  const [couriersByKey, setCouriersByKey] = React.useState(
    {} as { [key: string]: TransportCourier }
  );

  React.useEffect(() => {
    Gateway.get(`/transportadmin/list-all-couriers`).then((data) => {
      const byKey: any = {};
      for (const c of data) {
        byKey[courierToCourierKey(c)] = c;
      }
      setCouriersByKey(byKey);
    });
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const doSearch = (
    businessId: string,
    start: string,
    end: string
  ): void => {
    setOrders(null);
    Gateway.get(
      `/transportadmin/providercompanies/businessId/${businessId}/history?start=${start}&end=${end}`
    ).then((data) => setOrders(data));
  };

  const searchPressed = (): void => {
    if (providerCompanyBusinessID) {
      doSearch(providerCompanyBusinessID, startDate, endDate);
    }
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseMerchant')}</label>
        <ProviderCompanySelector
          onChange={setProviderCompanyBusinessID}
        />
      </div>
      {providerCompanyBusinessID && (
        <React.Fragment>
          <DateSearchBox
            transl={transl}
            startDate={startDate}
            endDate={endDate}
            startDateChanged={setStartDate}
            endDateChanged={setEndDate}
            searchPressed={searchPressed}
          />
          {orders === null && <LoadingComponent />}
          {orders !== null && (
            <div style={commonStyles.defaultContainer}>
              <FixedTable
                header={
                  <tr>
                    <th>{transl('OrderNumber')}</th>
                    <th>{transl('State')}</th>
                    <th>{transl('DistanceinKm')}</th>
                    <th>{transl('OrderPlacedTimestamp')}</th>
                    <th>{transl('PickupAddress')}</th>
                    <th>{transl('RecipientAddress')}</th>
                    <th>{transl('PickupTime')}</th>
                    <th>{transl('Courier')}</th>
                    <th>{transl('ChangeCourier')}</th>
                    <th>{transl('UpdateNumPackages')}</th>
                  </tr>
                }
              >
                {orders.map((attrs, i) => {
                  return (
                    <HistoryListItem
                      transl={transl}
                      key={i}
                      attrs={attrs}
                      openCourierChangePopup={(): any =>
                        setChangeCourierPopupData(attrs)
                      }
                      courier={
                        couriersByKey[attrs.courierEmailAndCompany]
                      }
                      openDetailsPopup={(): void => {
                        setChosenOrderKeyBase32(
                          dynamoKeysToBase32(
                            attrs.providerCompanyBusinessID,
                            attrs.externalID
                          )
                        );
                      }}
                      openUpdateNumPackagesPopup={() =>
                        setUpdateNumPackagesPopupAttrs(attrs)
                      }
                    />
                  );
                })}
              </FixedTable>
            </div>
          )}
          {false && (
            <div
              style={{
                padding: '15px',
              }}
            >
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={(): void =>
                  setRetroactiveOrderCreationPopupOpen(true)
                }
              >
                {transl('AddOrderRetroactively')}
              </button>
            </div>
          )}
        </React.Fragment>
      )}
      {changeCourierPopupData && (
        <ChangeCourierPopup
          closePopup={(): void => setChangeCourierPopupData(null)}
          reload={searchPressed}
          transl={transl}
          transportOrderId={changeCourierPopupData.transportOrderId}
        />
      )}
      {chosenOrderKeyBase32 && (
        <OrderDetailsPopup
          closePopup={(): void => setChosenOrderKeyBase32(null)}
          transl={transl}
          orderIdBase32={chosenOrderKeyBase32}
        />
      )}
      {retroactiveOrderCreationPopupOpen && (
        <CreateRetroactiveOrderPopup
          closePopup={(): void =>
            setRetroactiveOrderCreationPopupOpen(false)
          }
          transl={transl}
        />
      )}
      {updateNumPackagesPopupAttrs && (
        <UpdateNumPackagesPopup
          closePopup={(): void => {
            setUpdateNumPackagesPopupAttrs(null);
            searchPressed();
          }}
          closeParentPopup={(): void => {
            setUpdateNumPackagesPopupAttrs(null);
            searchPressed();
          }}
          transl={transl}
          attrs={updateNumPackagesPopupAttrs}
        />
      )}
    </div>
  );
};
