import React from 'react';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import colors from 'lib/colors';
import { MonitoringCounts, MonitoringData } from './types';
import { CouriersOnlineView } from './CouriersOnlineView';

import Gateway from '../web/gateway';

type MonitoringListItemProps = {
  attrs: MonitoringCounts;
  transl: (label: string) => string;
  key: any;
};

const getPanelSeverityNo = (attrs: MonitoringCounts): number => {
  if (attrs.couriersOnline > 0 && attrs.unallocatedOrders === 0) {
    return 2;
  }
  if (attrs.unallocatedOrders > 0) {
    return 0;
  }
  return 1;
};

const getPanelSortingNo = (attrs: MonitoringCounts): number => {
  return -2 * attrs.unallocatedOrders - attrs.ordersInDelivery;
};

const MonitoringListItem = ({
  attrs,
  transl,
}: MonitoringListItemProps): JSX.Element => {
  const panelClass = [
    'panel-danger',
    'panel-warning',
    'panel-success',
  ][getPanelSeverityNo(attrs)];

  const [monitoringData, setMonitoringData] = React.useState(
    null as MonitoringData | null
  );

  const refresh = React.useCallback((): any => {
    setMonitoringData(null);
    Gateway.get(`/transportadmin/citymonitoring/${attrs.city}`).then(
      setMonitoringData
    );
  }, [attrs.city]);

  React.useEffect(() => {
    refresh();
    return (): void => {
      // Intentionally empty
    };
  }, [refresh]);

  return (
    <div className={`panel ${panelClass}`}>
      <div className="panel-heading">{attrs.cityName}</div>
      <div className="panel-body">
        <label
          style={{
            display: 'block',
            color:
              attrs.unallocatedOrders === 0
                ? 'black'
                : attrs.couriersOnline > 0
                ? colors.warning
                : colors.danger,
          }}
        >
          {transl('UnallocatedOrders')}: {attrs.unallocatedOrders}
        </label>
        {monitoringData === null ? (
          <LoadingComponent />
        ) : (
          <CouriersOnlineView
            refresh={refresh}
            transl={transl}
            couriers={monitoringData.couriersOnline}
            nofUnallocatedOrders={
              monitoringData.unallocatedOrders.length
            }
          />
        )}
      </div>
    </div>
  );
};

export const MonitoringView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [monitoringData, setMonitoringData] = React.useState(
    null as MonitoringCounts[] | null
  );

  const refresh = (): any => {
    setMonitoringData(null);
    Gateway.get(`/transportadmin/monitoring`).then(
      (data: MonitoringCounts[]) => {
        data.sort(
          (a, b) => getPanelSortingNo(a) - getPanelSortingNo(b)
        );
        setMonitoringData(data);
      }
    );
  };

  React.useEffect(() => {
    refresh();
    return (): void => {
      // Intentionally empty
    };
  }, []);

  if (monitoringData === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-md"
          onClick={refresh}
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
          &nbsp;{transl('Refresh')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        {monitoringData
          .filter((item) => item.couriersOnline > 0)
          .map((attrs) => (
            <MonitoringListItem
              key={attrs.city}
              attrs={attrs}
              transl={transl}
            />
          ))}
      </div>
    </div>
  );
};
