/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import { dynamoKeysToBase32 } from 'lib/helpers';
import Spinner from 'lib/Spinner';
import { notify } from 'lib/Notifier';
import { TransportCourierCreateData } from './types';
import Gateway from '../web/gateway';
import FinnishBankUtils from 'finnish-bank-utils';
import moment from 'lib/moment';

const requiredFields = [
  'email',
  'name',
  'phone',
  'country',
  'language',
];
const requiredFieldsForSubcontractors = [
  'iban',
  'subcontractorCompanyBid',
  'subcontractorCompanyName',
  'subcontractorCompanyAddress',
  'subcontractorCompanyPostalcode',
  'subcontractorCompanyCity',
  'subcontractorCompanyEmail',
];

const emptyStringFieldsToNull = (attrs: any): any => {
  const res = JSON.parse(JSON.stringify(attrs));
  for (const key of Object.keys(res)) {
    if (res[key] === '') res[key] = null;
  }
  return res;
};

const processCourierFields = (
  attrs: any,
  prevCourierFields: TransportCourierCreateData
) => {
  const res = emptyStringFieldsToNull(attrs);
  if (
    prevCourierFields.dataloggerNo !== res.dataloggerNo &&
    res.dataloggerNo &&
    prevCourierFields.dataloggerNo
  ) {
    const prevLoggers = prevCourierFields.previousLoggers.slice();
    prevLoggers.unshift({
      loggerNo: prevCourierFields.dataloggerNo,
      loggerEndTimestamp: moment().toISOString(),
    });
    res.previousLoggers = prevLoggers;
  }
  return res;
};

const validateCourierAttrs = (
  attrs: TransportCourierCreateData
): boolean => {
  const data = emptyStringFieldsToNull(attrs);
  for (const field of requiredFields) {
    if (!data[field]) return false;
  }
  if (data.isSubcontractor) {
    for (const field of requiredFieldsForSubcontractors) {
      if (!data[field]) return false;
    }
  }
  return true;
};

const TransportCourierPopup = ({
  closePopup,
  reloadCouriers,
  transl,
  attrs,
  isNew,
  isMainTransportCompany,
}: {
  closePopup: () => any;
  reloadCouriers: () => any;
  transl: (label: string) => string;
  attrs: TransportCourierCreateData;
  isNew: boolean;
  isMainTransportCompany: boolean;
}): JSX.Element => {
  const [courierAttrs, setCourierAttrs] = React.useState(attrs);
  const [loading, setLoading] = React.useState(false);

  const getCourierBase32Id = (): string =>
    dynamoKeysToBase32(attrs.companyBusinessID, attrs.email);

  const saveData = (): void => {
    if (
      courierAttrs.iban &&
      !FinnishBankUtils.isValidFinnishIBAN(courierAttrs.iban)
    ) {
      alert(transl('CheckIBANMessage'));
      return;
    }
    setLoading(true);

    if (isNew) {
      Gateway.post(`/transportadmin/couriers`, {
        data: processCourierFields(courierAttrs, attrs),
      })
        .then(reloadCouriers)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      Gateway.put(
        `/transportadmin/couriers/id/${getCourierBase32Id()}`,
        {
          data: processCourierFields(courierAttrs, attrs),
        }
      )
        .then(reloadCouriers)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }

    closePopup();
  };

  const deleteCourier = (): void => {
    if (isNew) return;
    if (
      window.confirm(
        transl('ConfirmDeleteCourier').replace('#NAME#', attrs.name)
      )
    ) {
      setLoading(true);
      Gateway.del(
        `/transportadmin/couriers/id/${getCourierBase32Id()}`
      )
        .then(reloadCouriers)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      closePopup();
    }
  };

  const resetCourierPassword = (): void => {
    if (isNew) return;
    if (
      window.confirm(
        transl('ConfirmResetCourier').replace('#NAME#', attrs.name)
      )
    ) {
      setLoading(true);
      Gateway.post(
        `/transportadmin/couriers/id/${getCourierBase32Id()}/reset`,
        {}
      )
        .then(() => setLoading(false))
        .then(() => notify(transl('CourierResetComplete')))
        .catch(() => setLoading(false));
      closePopup();
    }
  };

  const setVal = (key: string, value: any): void => {
    setCourierAttrs((prevAttrs) => ({ ...prevAttrs, [key]: value }));
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  const attrsValid = validateCourierAttrs(courierAttrs);

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>
            {isNew
              ? transl('AddNewCourier')
              : transl('ModifyCourier')}
          </h2>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-email">
              {transl('Email')}
            </label>
            <input
              type="text"
              value={courierAttrs.email}
              className="form-control"
              id="transportcourierpopup-email"
              placeholder="john.doe@example.com"
              maxLength={100}
              disabled={!isNew}
              onChange={(e): void =>
                setVal('email', e.target.value.toLowerCase().trim())
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-name">
              {transl('Name')}
            </label>
            <input
              type="text"
              value={courierAttrs.name}
              className="form-control"
              id="transportcourierpopup-name"
              placeholder="John Doe"
              maxLength={100}
              onChange={(e): void => setVal('name', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-phone">
              {transl('Phone')}
            </label>
            <input
              type="text"
              value={courierAttrs.phone}
              className="form-control"
              id="transportcourierpopup-phone"
              placeholder="+358 40 1112233"
              maxLength={100}
              onChange={(e): void => setVal('phone', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>{transl('Language')}</label>
            <select
              className="form-control"
              value={courierAttrs.language}
              onChange={(e): void =>
                setVal('language', e.target.value)
              }
            >
              <option value="fi">Suomi</option>
              <option value="en">English</option>
            </select>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={courierAttrs.isSubcontractor}
                onChange={(e): void => {
                  const newVal = (e.target as any).checked as boolean;
                  setVal('isSubcontractor', newVal);
                  if (newVal) {
                    setVal('isFreelancer', false);
                  }
                }}
              />
              &nbsp;&nbsp;{transl('IsSubcontractor')}
            </label>
          </div>
          {isMainTransportCompany && (
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={courierAttrs.isFreelancer}
                  onChange={(e): void => {
                    const newVal = (e.target as any)
                      .checked as boolean;
                    setVal('isFreelancer', newVal);
                    if (newVal) {
                      setVal('isSubcontractor', false);
                    }
                  }}
                />
                &nbsp;&nbsp;{transl('isFreelancer')}
              </label>
            </div>
          )}
          <h3>{transl('SubcontractorData')}</h3>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-iban">
              {transl('IBAN')}
            </label>
            <input
              type="text"
              value={courierAttrs.iban || ''}
              className="form-control"
              id="transportcourierpopup-iban"
              placeholder="FI7655060020083232"
              maxLength={100}
              onChange={(e): void =>
                setVal('iban', e.target.value.trim())
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-subcontractorCompanyBid">
              {transl('CompanyBid')}
            </label>
            <input
              type="text"
              value={courierAttrs.subcontractorCompanyBid || ''}
              className="form-control"
              id="transportcourierpopup-subcontractorCompanyBid"
              placeholder="1234567-8"
              maxLength={100}
              onChange={(e): void =>
                setVal(
                  'subcontractorCompanyBid',
                  e.target.value.trim()
                )
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-subcontractorCompanyName">
              {transl('CompanyName')}
            </label>
            <input
              type="text"
              value={courierAttrs.subcontractorCompanyName || ''}
              className="form-control"
              id="transportcourierpopup-subcontractorCompanyName"
              placeholder="Company Oy"
              maxLength={100}
              onChange={(e): void =>
                setVal('subcontractorCompanyName', e.target.value)
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-subcontractorCompanyAddress">
              {transl('CompanyAddress')}
            </label>
            <input
              type="text"
              value={courierAttrs.subcontractorCompanyAddress || ''}
              className="form-control"
              id="transportcourierpopup-subcontractorCompanyAddress"
              placeholder="Street 1 B 12"
              maxLength={100}
              onChange={(e): void =>
                setVal('subcontractorCompanyAddress', e.target.value)
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-subcontractorCompanyPostalcode">
              {transl('PostalCode')}
            </label>
            <input
              type="text"
              value={
                courierAttrs.subcontractorCompanyPostalcode || ''
              }
              className="form-control"
              id="transportcourierpopup-subcontractorCompanyPostalcode"
              placeholder="12345"
              maxLength={10}
              onChange={(e): void =>
                setVal(
                  'subcontractorCompanyPostalcode',
                  e.target.value
                )
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-subcontractorCompanyCity">
              {transl('PostalCity')}
            </label>
            <input
              type="text"
              value={courierAttrs.subcontractorCompanyCity || ''}
              className="form-control"
              id="transportcourierpopup-subcontractorCompanyCity"
              placeholder="Helsinki"
              onChange={(e): void =>
                setVal('subcontractorCompanyCity', e.target.value)
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <div className="form-group">
            <label>{transl('Country')}</label>
            <select
              className="form-control"
              value={courierAttrs.country}
              onChange={(e): void =>
                setVal('country', e.target.value)
              }
            >
              <option value="FI">Suomi</option>
              <option value="SE">Sverige</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="transportcourierpopup-subcontractorCompanyEmail">
              {transl('CompanyEmail')}
            </label>
            <input
              type="text"
              value={courierAttrs.subcontractorCompanyEmail || ''}
              className="form-control"
              id="transportcourierpopup-subcontractorCompanyEmail"
              onChange={(e): void =>
                setVal(
                  'subcontractorCompanyEmail',
                  e.target.value.toLowerCase().trim()
                )
              }
              disabled={!courierAttrs.isSubcontractor}
            />
          </div>
          <h3>{transl('ThermoLoggerInfo')}</h3>
          <h4>{transl('PreviousLoggers')}</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{transl('LoggerNo')}</th>
                <th>{transl('EndTimestamp')}</th>
              </tr>
            </thead>
            <tbody>
              {attrs.previousLoggers.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.loggerNo}</td>
                    <td>
                      {moment(item.loggerEndTimestamp).format('L')}{' '}
                      {moment(item.loggerEndTimestamp).format(
                        'HH:mm'
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!isNew && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                onClick={resetCourierPassword}
              >
                {transl('ResetCredentials')}
              </button>
            </div>
          )}
          {!isNew && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-warning btn-lg btn-block"
                onClick={deleteCourier}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                &nbsp;&nbsp;{transl('Delete')}
              </button>
            </div>
          )}
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={!attrsValid}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Confirm')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};

export default TransportCourierPopup;
