import React from 'react';
import moment from 'moment';
import Select from 'react-virtualized-select';
import Spinner from 'lib/Spinner';
import { dynamoKeysFromBase32 } from 'lib/helpers';
import { commonStyles } from 'lib/commonStyles';

import {
  SpecialOrder,
  ScheduleItem,
  updateSchedule,
  createOrderRetroactively,
  specialOrderHistory,
  TransportCourier,
} from './helpers';
import { ProviderCompanySelector } from '../orders/ProviderCompanySelector';
import { AddScheduleItemPopup } from './AddScheduleItemPopup';
import { AddScheduleItemRetroactivelyPopup } from './AddScheduleItemRetroactivelyPopup';
import { SpecialOrderHistoryView } from './SpecialOrderHistoryView';
import { TransportOrder } from '../orders/types';

type SelectOption = {
  label: string;
  value: string;
};

export const SpecialOrderScheduleListView = ({
  transl,
  orders,
  reload,
  couriers,
}: {
  transl: (label: string) => string;
  orders: SpecialOrder[];
  reload: () => Promise<void>;
  couriers: TransportCourier[];
}): JSX.Element => {
  const [chosenProviderCompany, setChosenProviderCompany] =
    React.useState<string | null>(null);

  const [chosenOrderId, setChosenOrderId] = React.useState(
    null as string | null
  );
  const [loading, setLoading] = React.useState(false);

  const [addScheduleItemPopupOpen, setAddScheduleItemPopupOpen] =
    React.useState(false);

  const [
    addScheduleItemRetroactivelyPopupOpen,
    setAddScheduleItemRetroactivelyPopupOpen,
  ] = React.useState(false);

  const [historyItems, setHistoryItems] = React.useState<
    TransportOrder[]
  >([]);

  React.useEffect(() => {
    if (chosenOrderId) {
      specialOrderHistory(
        chosenOrderId,
        moment().subtract(14, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ).then(setHistoryItems);
    }
  }, [chosenOrderId]);

  const refresh = async () => {
    if (chosenOrderId) {
      specialOrderHistory(
        chosenOrderId,
        moment().subtract(14, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ).then(setHistoryItems);
    }
    await reload();
  };

  const orderOptions: SelectOption[] = orders
    .filter((item) => !!item.id)
    .filter(
      (item) =>
        item.providerCompanyBusinessID === chosenProviderCompany
    )
    .map((order) => ({
      label: order.name,
      value: order.id || '',
    }));
  const orderSelectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    setChosenOrderId(newSelection ? newSelection.value : null);
  };
  const currentOrderSelectOption = orderOptions.find(
    (item) => item.value === chosenOrderId
  );

  const chosenOrder = orders.find(
    (item) => item.id === chosenOrderId
  );

  const addScheduleItem = async (
    item: ScheduleItem
  ): Promise<void> => {
    if (!chosenOrder) return;
    setLoading(true);
    const s = chosenOrder.schedule;
    s.push(item);
    s.sort((a, b) => {
      return moment(a.orderCreationTimestamp).isAfter(
        b.orderCreationTimestamp
      )
        ? 1
        : -1;
    });
    try {
      await updateSchedule(chosenOrder.id, s);
      await refresh();
    } finally {
      setLoading(false);
    }
  };

  const removeScheduleItem = async (
    item: ScheduleItem
  ): Promise<void> => {
    if (!chosenOrder) return;
    setLoading(true);
    let s = chosenOrder.schedule;
    s = s.filter(
      (attrs) =>
        attrs.orderCreationTimestamp !==
          item.orderCreationTimestamp ||
        item.courierBase32 !== attrs.courierBase32
    );
    try {
      await updateSchedule(chosenOrder.id, s);
      await refresh();
    } finally {
      setLoading(false);
    }
  };

  const addScheduleItemRetroactively = async (
    courierBase32: string,
    externalID: string,
    startTimestamp: string,
    endTimestamp: string
  ): Promise<void> => {
    if (!chosenOrder) return;
    setLoading(true);
    try {
      await createOrderRetroactively(
        chosenOrder.id,
        courierBase32,
        externalID,
        startTimestamp,
        endTimestamp
      );
      await refresh();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseMerchant')}</label>
        <ProviderCompanySelector
          onChange={setChosenProviderCompany}
        />
      </div>
      {chosenProviderCompany && (
        <div style={{ padding: '15px' }}>
          <label>{transl('ChooseSpecialOrder')}</label>
          <Select
            options={orderOptions}
            // @ts-ignore
            onChange={orderSelectionChanged}
            value={currentOrderSelectOption}
          />
        </div>
      )}
      {chosenOrder && (
        <div style={commonStyles.defaultContainer}>
          <div>
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={(): void => {
                setAddScheduleItemPopupOpen(true);
              }}
            >
              {transl('AddOrder')}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={(): void => {
                setAddScheduleItemRetroactivelyPopupOpen(true);
              }}
            >
              {transl('AddOrderRetroactively')}
            </button>
          </div>
          <h3>{transl('FutureOrders')}</h3>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>{transl('PickupTime')}</th>
                <th>{transl('OrderAllocationMinutes')}</th>
                <th>{transl('Courier')}</th>
                <th>{transl('Remove')}</th>
              </tr>
            </thead>
            <tbody>
              {chosenOrder.schedule.map((scheduleItem, i) => {
                const pickupTime = moment(
                  scheduleItem.orderCreationTimestamp
                ).add(
                  scheduleItem.timeUntilEarliestPickupTimeMinutes ||
                    0,
                  'minutes'
                );

                return (
                  <tr key={i}>
                    <td>
                      {pickupTime.format('L')}{' '}
                      {pickupTime.format('HH:mm')}
                    </td>
                    <td>
                      {`${
                        scheduleItem.timeUntilEarliestPickupTimeMinutes ||
                        ''
                      }`}
                    </td>
                    <td>
                      {scheduleItem.courierBase32
                        ? dynamoKeysFromBase32(
                            scheduleItem.courierBase32
                          )?.rangeKey
                        : '-'}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-md"
                        onClick={(): Promise<void> =>
                          removeScheduleItem(scheduleItem)
                        }
                      >
                        &nbsp;&nbsp;{transl('Remove')}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <SpecialOrderHistoryView
            transl={transl}
            couriers={couriers}
            orders={historyItems}
            refresh={refresh}
          />
        </div>
      )}
      {addScheduleItemPopupOpen && chosenOrder && (
        <AddScheduleItemPopup
          closePopup={(): void => setAddScheduleItemPopupOpen(false)}
          transl={transl}
          addScheduleItem={addScheduleItem}
          timezone={chosenOrder.timezone}
        />
      )}
      {addScheduleItemRetroactivelyPopupOpen && chosenOrder && (
        <AddScheduleItemRetroactivelyPopup
          closePopup={(): void =>
            setAddScheduleItemRetroactivelyPopupOpen(false)
          }
          transl={transl}
          addScheduleItemRetroactively={addScheduleItemRetroactively}
          timezone={chosenOrder.timezone}
        />
      )}
      <Spinner visible={loading} />
    </div>
  );
};
