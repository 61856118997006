import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import Modal from 'lib/Modal';
import { TransportOrder } from '../orders/types';

import Gateway from '../web/gateway';

export const CancelOrderPopup = ({
  closePopup,
  closeParentPopup,
  transl,
  attrs,
}: {
  closePopup: () => any;
  closeParentPopup: () => any;
  transl: (label: string) => string;
  attrs: TransportOrder;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [retainPayments, setRetainPayments] = React.useState(false);

  const doCancelOrder = (): void => {
    setLoading(true);
    Gateway.post(
      `/transportadmin/order/${attrs.transportOrderId}/cancel`,
      { retainPayments }
    )
      .then(closeParentPopup)
      .catch((err) => {
        alert('An error occurred: ' + err.toString());
      });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '400px',
      maxHeight: window.innerHeight - 100,
      width: '400px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'tyur'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('CancelOrder')}</h2>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={retainPayments}
                onChange={(e): void =>
                  setRetainPayments((e.target as any).checked)
                }
              />
              &nbsp;&nbsp;{transl('RetainPaymentsMsg')}
            </label>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={doCancelOrder}
            >
              {transl('CancelOrder')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
