import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';

import { TransportOrder } from '../orders/types';
import { ManualAllocationPopup } from './ManualAllocationPopup';
import { CourierOnlineData } from './types';

const orderHasAlert = (order: TransportOrder): boolean => {
  if (order.serviceLevel !== 'samehour') return false;
  if (order.state === 'orderPlaced') {
    if (order.rejectedByCouriers) return true;
    const alertTimestamp = !!order.earliestPickupTime
      ? moment(order.earliestPickupTime || undefined).subtract(
          10,
          'minutes'
        )
      : moment(order.orderPlacedTimestamp || undefined).add(
          5,
          'minutes'
        );
    if (alertTimestamp.isSameOrBefore(moment())) {
      return true;
    }
  }
  return false;
};

const formatPickuptime = (
  earliestPickupTime: string | null | undefined,
  immediatelyStr: string
) => {
  return !!earliestPickupTime
    ? moment(earliestPickupTime).format('L') +
        ' ' +
        moment(earliestPickupTime).format('HH:mm')
    : immediatelyStr;
};

export const UnallocatedOrdersView = ({
  transl,
  orders,
  couriersOnline,
  refresh,
  urgent,
}: {
  transl: (label: string) => string;
  orders: TransportOrder[];
  couriersOnline: CourierOnlineData[];
  refresh: () => any;
  urgent?: boolean;
}): JSX.Element => {
  const [popupData, setPopupData] = React.useState(
    null as TransportOrder | null
  );

  return (
    <div
      style={{ ...commonStyles.defaultContainer, minHeight: '300px' }}
    >
      <h3 style={{ color: urgent ? 'red' : 'black' }}>
        {urgent
          ? transl('OrdersThatAreUrgent')
          : transl('OrdersThatAreUnallocated')}
      </h3>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('Notes')}</th>
            <th>{transl('OrderPlacedTimestamp')}</th>
            <th>{transl('PickupTime')}</th>
            <th>{transl('Sender')}</th>
            <th>{transl('PickupInstructions')}</th>
            <th>{transl('Recipient')}</th>
            <th>{transl('DeliveryInstructions')}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, i) => (
            <tr
              className={orderHasAlert(order) ? 'text-danger' : ''}
              style={{
                cursor: 'pointer',
              }}
              key={i}
              onClick={(): any => {
                if (
                  order.serviceLevel === 'samehour' ||
                  order.state !== 'orderPlaced'
                ) {
                  setPopupData(order);
                }
              }}
            >
              <td>
                {order.metaData?.packageType !== 'normal' && (
                  <span style={{ fontWeight: 'bold', color: 'red' }}>
                    {transl('RequiresCarType')}:{' '}
                    {transl(
                      'CarType' + (order.metaData?.packageType || '')
                    )}
                    <br />
                  </span>
                )}
                {order.isColdDelivery && (
                  <span style={{ fontWeight: 'bold', color: 'blue' }}>
                    {transl('ColdDelivery')}
                    <br />
                  </span>
                )}
                {order.numPackages > 1 && (
                  <span
                    style={{ fontWeight: 'bold', color: 'black' }}
                  >
                    {transl('NumberOfPackages')}: {order.numPackages}
                    <br />
                  </span>
                )}
                {order.isCriticallyUrgent && (
                  <span style={{ fontWeight: 'bold', color: 'red' }}>
                    {transl('IsCriticallyUrgent')}
                    <br />
                  </span>
                )}
              </td>
              <td>
                {moment(order.orderPlacedTimestamp as string).format(
                  'L'
                )}{' '}
                {moment(order.orderPlacedTimestamp as string).format(
                  'HH:mm'
                )}
              </td>
              <td>
                {formatPickuptime(
                  order.earliestPickupTime,
                  order.serviceLevel === 'samehour'
                    ? transl('Now')
                    : '-'
                )}
                {(order.earliestPickupTime || null) !==
                  (order.metaData?.earliestPickupTime || null) && (
                  <small>
                    <br />
                    {transl('Originally')}:{' '}
                    {formatPickuptime(
                      order.metaData?.earliestPickupTime,
                      transl('Now')
                    )}
                  </small>
                )}
              </td>
              <td>
                {order.providerCompanyName}
                <br />
                {order.startAddressTitle}
                <br />
                {order.startAddress}
                <br />
                {order.startPostalCode}
                <br />
                {order.startAddressPhone}
              </td>
              <td>{order.startAddressNotes}</td>
              <td>
                {order.recipientName}
                <br />
                {order.recipientAddress}
                <br />
                {order.recipientPostalCode}
              </td>
              <td>{order.recipientAddressNotes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {popupData && (
        <ManualAllocationPopup
          transl={transl}
          attrs={popupData}
          couriersOnline={couriersOnline}
          closePopup={(): any => {
            setPopupData(null);
            refresh();
          }}
        />
      )}
    </div>
  );
};
