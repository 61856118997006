import React from 'react';
import moment from 'moment';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import Modal from 'lib/Modal';
import {
  getDistanceFromLatLonInKm,
  dynamoKeysToBase32,
} from 'lib/helpers';
import { CourierOnlineData } from './types';
import { TransportOrder } from '../orders/types';
import { TransportCourier } from '../transportCouriers/types';
import { LoadingComponent } from 'lib/LoadingComponent';
import { CancelOrderPopup } from './CancelOrderPopup';
import { SetPickupTimePopup } from './SetPickupTimePopup';
import { MarkOrderDeliveredPopup } from './MarkOrderDeliveredPopup';
import { UpdateNumPackagesPopup } from './UpdateNumPackagesPopup';
import { SendToMyxlinePopup } from '../samedayOrders/SendToMyxlinePopup';

import Gateway from '../web/gateway';

type CourierAndDistanceFromOrder = {
  courierData: CourierOnlineData;
  distanceInKm: number;
};

const getDistanceBetweenCourierAndOrder = (
  courier: TransportCourier,
  order: TransportOrder
): number => {
  if (!courier.currentLocationLat || !courier.currentLocationLong)
    return -1;
  return getDistanceFromLatLonInKm(
    courier.currentLocationLat,
    courier.currentLocationLong,
    order.startLat,
    order.startLong
  );
};

const getAllocationUrl = (
  order: TransportOrder,
  courier: TransportCourier
): string => {
  if (order.courierEmailAndCompany) {
    return `/transportadmin/order/${
      order.transportOrderId
    }/courier/${dynamoKeysToBase32(
      courier.companyBusinessID,
      courier.email
    )}/reallocate`;
  }
  return `/transportadmin/order/${
    order.transportOrderId
  }/courier/${dynamoKeysToBase32(
    courier.companyBusinessID,
    courier.email
  )}/allocate`;
};

export const ManualAllocationPopup = ({
  closePopup,
  transl,
  attrs,
  couriersOnline,
}: {
  closePopup: () => any;
  transl: (label: string) => string;
  attrs: TransportOrder;
  couriersOnline: CourierOnlineData[];
}): JSX.Element => {
  const [couriers, setCouriers] = React.useState(
    null as CourierAndDistanceFromOrder[] | null
  );
  const [loading, setLoading] = React.useState(false);
  const [cancelOrderPopupOpen, setCancelOrderPopupOpen] =
    React.useState(false);
  const [markDeliveredPopupOpen, setMarkDeliveredPopupOpen] =
    React.useState(false);
  const [setPickupTimePopupOpen, setSetpickupTimePopupOpen] =
    React.useState(false);
  const [updateNumPackagesPopupOpen, setUpdateNumPackagesPopupOpen] =
    React.useState(false);
  const [sendToMyxlinePopupOpen, setSendToMyxlinePopupOpen] =
    React.useState(false);

  const courierChosenButNotConfirmed =
    attrs.state === 'courierChosen' && !attrs.courierConfirmed;

  const hourFromNow = moment().add(1, 'hour');
  const pickupIsOverAnHourAway = !!attrs.earliestPickupTime
    ? moment(attrs.earliestPickupTime).isAfter(hourFromNow)
    : false;

  const allocateOrder = (courier: TransportCourier): void => {
    if (
      !window.confirm(
        transl('AllocateToCourier')
          .split('#COURIER#')
          .join(courier.name)
      )
    )
      return;
    setLoading(true);
    Gateway.post(getAllocationUrl(attrs, courier), {})
      .then(closePopup)
      .catch((err) => {
        setLoading(false);
        alert('An error occurred: ' + err);
      });
  };

  const openCancelOrderPopup = (): void => {
    setCancelOrderPopupOpen(true);
  };

  const openMarkDeliveredPopup = (): void => {
    setMarkDeliveredPopupOpen(true);
  };

  const openSendToMyxlinePopup = (): void => {
    setSendToMyxlinePopupOpen(true);
  };

  React.useEffect(() => {
    const couriersAndDistances = couriersOnline
      .map((courierData) => ({
        courierData,
        distanceInKm: getDistanceBetweenCourierAndOrder(
          courierData.courier,
          attrs
        ),
      }))
      .filter(
        (item) => !item.courierData.courier.isExternalAndTemporary
      )
      .filter(
        (data) => data.distanceInKm >= 0 && data.distanceInKm < 200
      );
    couriersAndDistances.sort(
      (a, b) => a.distanceInKm - b.distanceInKm
    );
    setCouriers(couriersAndDistances);
    return (): void => {
      // Intentionally empty
    };
  }, [couriersOnline, attrs]);

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '450px',
      maxHeight: window.innerHeight - 100,
      width: '1200px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'tyur'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('AllocateOrder')}</h2>
          {attrs.metaData?.packageType &&
            attrs.metaData.packageType !== 'normal' && (
              <p className="text-danger">
                {transl(
                  'ThisOrderCantBeTransportedInNormalCarWarning'
                )}
              </p>
            )}
          <b>{transl('OrderNumber')}:</b> {attrs.externalID}
          {couriers === null && <LoadingComponent />}
          {couriers !== null && (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>{transl('Name')}</th>
                  <th>{transl('Email')}</th>
                  <th>{transl('Phone')}</th>
                  <th>{transl('OrdersInDelivery')}</th>
                  <th>{transl('DistanceinKm')}</th>
                  <th>{transl('AllocateOrder')}</th>
                </tr>
              </thead>
              <tbody>
                {couriers.map((c, i) => (
                  <tr key={i}>
                    <td>{c.courierData.courier.name}</td>
                    <td>{c.courierData.courier.email}</td>
                    <td>{c.courierData.courier.phone}</td>
                    <td>{c.courierData.undelivered}</td>
                    <td>{c.distanceInKm.toFixed(3)}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-md"
                        disabled={
                          courierChosenButNotConfirmed ||
                          attrs.serviceLevel === 'sameday' ||
                          pickupIsOverAnHourAway ||
                          (attrs.temperatureMonitoringRequired &&
                            !c.courierData.courier
                              .medicalDeliveryTrainingCompleted)
                        }
                        onClick={(): any =>
                          allocateOrder(c.courierData.courier)
                        }
                      >
                        {transl('AllocateOrder')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div style={{ paddingTop: '15px' }}>
            <button
              type="button"
              className="btn btn-danger btn-md"
              onClick={openCancelOrderPopup}
            >
              {transl('CancelOrder')}
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={openMarkDeliveredPopup}
            >
              {transl('MarkDelivered')}
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={(): void => setSetpickupTimePopupOpen(true)}
            >
              {transl('SetPickupTime')}
            </button>
            &nbsp; &nbsp; &nbsp;
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={(): void =>
                setUpdateNumPackagesPopupOpen(true)
              }
            >
              {transl('UpdateNumPackages')}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={openSendToMyxlinePopup}
            >
              {transl('SendToMyxline')}
            </button>
          </div>
        </div>
      </Modal>
      {cancelOrderPopupOpen && (
        <CancelOrderPopup
          closePopup={(): void => setCancelOrderPopupOpen(false)}
          closeParentPopup={closePopup}
          transl={transl}
          attrs={attrs}
        />
      )}
      {markDeliveredPopupOpen && (
        <MarkOrderDeliveredPopup
          closeParentPopup={closePopup}
          setLoading={setLoading}
          closePopup={(): void => setMarkDeliveredPopupOpen(false)}
          transl={transl}
          transportOrderId={attrs.transportOrderId}
        />
      )}
      {setPickupTimePopupOpen && (
        <SetPickupTimePopup
          closePopup={(): void => setSetpickupTimePopupOpen(false)}
          closeParentPopup={closePopup}
          transl={transl}
          attrs={attrs}
        />
      )}
      {updateNumPackagesPopupOpen && (
        <UpdateNumPackagesPopup
          closePopup={(): void => setCancelOrderPopupOpen(false)}
          closeParentPopup={closePopup}
          transl={transl}
          attrs={attrs}
        />
      )}
      {sendToMyxlinePopupOpen && (
        <SendToMyxlinePopup
          closePopup={(): void => setSendToMyxlinePopupOpen(false)}
          closeParentPopup={closePopup}
          transl={transl}
          attrs={attrs}
        />
      )}
      <Spinner visible={loading} />
    </div>
  );
};
