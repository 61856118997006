/* eslint-disable no-console */
import { BASE_URL, BASE_HEADERS } from './constants';
import moment, { Moment } from 'moment';

let refreshToken: string | null = null;
let apiToken: string | null = null;
let apiTokenExpiryTimestamp: Moment | null = null;
let refreshTokenExpiryTimestamp: Moment | null = null;
let tokenUpdateInProcess = false;

let logoutCallBack: (() => void) | null = null;

const sleep = (ms: number): Promise<any> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const makeRawRequest = async (
  method: string,
  url: string,
  additionalHeaders: any = {},
  data?: any
): Promise<any> => {
  let res: any;
  try {
    const meta: any = {
      method,
      headers: { ...BASE_HEADERS, ...additionalHeaders },
    };
    if (!['GET', 'OPTIONS', 'DELETE'].includes(method))
      meta.body = JSON.stringify(data);

    res = await fetch(BASE_URL + url, meta);
  } catch (error) {
    const exp = {
      status: 408,
    };
    throw exp;
  }
  if (res.ok) {
    try {
      const json = await res.json();

      return json.data || json;
    } catch (error) {
      return {};
    }
  } else {
    throw res;
  }
};

type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  accessTokenTTL: number;
  refreshTokenTTL: number;
};

const setTokenData = (loginRes: LoginResponse): any => {
  refreshToken = loginRes.refreshToken;
  apiToken = loginRes.accessToken;
  apiTokenExpiryTimestamp = moment().add(
    loginRes.accessTokenTTL,
    'seconds'
  );
  refreshTokenExpiryTimestamp = moment().add(
    loginRes.refreshTokenTTL,
    'seconds'
  );
};

const apiTokenExpired = (): boolean => {
  return moment()
    .add(10, 'seconds')
    .isAfter(apiTokenExpiryTimestamp || moment());
};

export const login = async (
  companyBusinessID: string,
  email: string,
  password: string
): Promise<any> => {
  const loginRes: LoginResponse = await makeRawRequest(
    'POST',
    '/transportadmin/login',
    {},
    {
      data: {
        companyBusinessID,
        email,
        password,
      },
    }
  );
  setTokenData(loginRes);
};

export const loginWithAdminToken = async (
  businessID: string,
  token: string
): Promise<any> => {
  const loginRes: LoginResponse = await makeRawRequest(
    'POST',
    '/transportadmin/login-with-token',
    {},
    {
      data: {
        businessID,
        token,
      },
    }
  );
  setTokenData(loginRes);
};

export const keepTokensUpToDate = async (): Promise<any> => {
  if (!refreshToken) {
    const exp = {
      status: 401,
    };
    throw exp;
  }
  if (tokenUpdateInProcess) {
    if (apiTokenExpired()) {
      while (tokenUpdateInProcess) {
        await sleep(100);
      }
    }
    return;
  }

  const inTwoMinutes = moment().add(120, 'seconds');
  if (inTwoMinutes.isAfter(apiTokenExpiryTimestamp || moment())) {
    tokenUpdateInProcess = true;
    const refreshRes: LoginResponse = await makeRawRequest(
      'POST',
      '/refresh_token',
      {},
      {
        refreshToken,
      }
    );
    setTokenData(refreshRes);
    tokenUpdateInProcess = false;
  }
};

export const refreshTokenExpired = (): boolean => {
  return moment()
    .add(1, 'seconds')
    .isAfter(refreshTokenExpiryTimestamp || moment());
};

export const makeRequestWithApiToken = async (
  method: string,
  url: string,
  data?: any
): Promise<any> => {
  try {
    if (refreshTokenExpired()) {
      const exp = {
        status: 401,
      };
      throw exp;
    }
    await keepTokensUpToDate();
    return await makeRawRequest(
      method,
      url,
      { 'x-api-token': apiToken },
      data
    );
  } catch (err: any) {
    if (typeof err === 'object' && err) {
      if (
        err?.status === 401 &&
        typeof logoutCallBack === 'function'
      ) {
        logoutCallBack();
        return {};
      }
      let jso: any = {};
      try {
        jso = await err.json();
      } catch (e: any) {
        throw err;
      }
      if (jso.message) {
        throw new Error(jso.message);
      }
    }
    throw err;
  }
};

export const setLogoutCallback = (cb: () => void): void => {
  logoutCallBack = cb;
};
