import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './globalStyles.css';

export const commonStyles: any = {
  defaultContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    padding: '15px',
  },

  flexColumnContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  flexRowContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
  },
  horizontalContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
  },
  flexCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  fixedContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  },
  textCenter: {
    textAlign: 'center',
  },
  borderBtm: {
    borderBottom: '1px solid #ccc',
  },
  popupScrollContainer: {
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '20px',
  },
  popupBtnContainer: {
    paddingBottom: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    width: '100%',
    display: 'flex',
    minHeight: '50px',
  },
  pickerWithButton: {
    width: '100%',
    height: '60px',
    display: 'flex',
    padding: '10px 0px',
  },
};
