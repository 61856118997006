import React from 'react';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import {
  TransportCourier,
  SpecialOrder,
  listCouriers,
  listSpecialOrders,
} from './helpers';
import { SpecialOrderScheduleCalendarWrapper } from './SpecialOrderScheduleCalendarWrapper';
import { SpecialOrderScheduleListView } from './SpecialOrderScheduleListView';

export const SpecialOrderScheduleView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [couriers, setCouriers] = React.useState(
    null as TransportCourier[] | null
  );
  const [orders, setOrders] = React.useState(
    null as SpecialOrder[] | null
  );

  const [chosenView, setChosenView] = React.useState<
    'calendar' | 'list'
  >('calendar');

  React.useEffect(() => {
    listCouriers().then(setCouriers);
    listSpecialOrders().then(setOrders);
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reload = async (): Promise<void> => {
    setOrders(await listSpecialOrders());
  };

  if (!couriers || !orders) {
    return <LoadingComponent />;
  }

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <div className="btn-group btn-group-justified">
          <span
            onClick={(): void => setChosenView('calendar')}
            className={
              'btn btn-primary' +
              (chosenView === 'calendar' ? ' active' : '')
            }
          >
            {transl('CalendarView')}
          </span>
          <span
            onClick={(): void => setChosenView('list')}
            className={
              'btn btn-primary' +
              (chosenView === 'list' ? ' active' : '')
            }
          >
            {transl('ListView')}
          </span>
        </div>
      </div>
      {chosenView === 'calendar' && (
        <SpecialOrderScheduleCalendarWrapper
          transl={transl}
          couriers={couriers}
          orders={orders}
          reload={reload}
        />
      )}
      {chosenView === 'list' && (
        <SpecialOrderScheduleListView
          transl={transl}
          orders={orders}
          reload={reload}
          couriers={couriers}
        />
      )}
    </div>
  );
};
