import React from 'react';

type Props = {
  visible: boolean;
  textContent?: string;
  textStyle?: any;
};

class Spinner extends React.Component<Props> {
  componentDidMount(): void {
    // @ts-ignore
    window.ajaxSpinner.propsChanged(
      this.props.visible,
      this.props.textContent,
      this.props.textStyle
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps): void {
    // @ts-ignore
    window.ajaxSpinner.propsChanged(
      nextProps.visible,
      nextProps.textContent,
      nextProps.textStyle
    );
  }

  componentWillUnmount(): void {
    // @ts-ignore
    window.ajaxSpinner.propsChanged(
      false,
      this.props.textContent,
      this.props.textStyle
    );
  }

  render(): null {
    return null;
  }
}
export default Spinner;
