import React from 'react';
import Select from 'react-virtualized-select';
import moment from 'lib/moment';
import { dynamoKeysToBase32 } from 'lib/helpers';
import { commonStyles } from 'lib/commonStyles';
import { TransportCourier, SpecialOrder } from './helpers';
import { SpecialOrderScheduleCalendarView } from './SpecialOrderScheduleCalendarView';

type SelectOption = {
  label: string;
  value: string;
};

export const SpecialOrderScheduleCalendarWrapper = ({
  transl,
  couriers,
  orders,
  reload,
}: {
  transl: (label: string) => string;
  couriers: TransportCourier[];
  orders: SpecialOrder[];
  reload: () => Promise<void>;
}): JSX.Element => {
  const [chosenCourierId, setChosenCourierId] = React.useState(
    null as string | null
  );
  const [chosenOrderId, setChosenOrderId] = React.useState(
    null as string | null
  );

  const [chosenWeekFirstDate, setChosenWeekFirstDate] =
    React.useState(moment().weekday(0).format('YYYY-MM-DD'));
  const chosenWeekLastDate = moment(chosenWeekFirstDate)
    .add(6, 'days')
    .format('YYYY-MM-DD');

  const previousWeek = (): void => {
    setChosenWeekFirstDate(
      moment(chosenWeekFirstDate)
        .subtract(7, 'days')
        .format('YYYY-MM-DD')
    );
  };

  const nextWeek = (): void => {
    setChosenWeekFirstDate(
      moment(chosenWeekFirstDate).add(7, 'days').format('YYYY-MM-DD')
    );
  };

  const courierOptions: SelectOption[] = couriers.map((courier) => ({
    label: `${courier.name} (${courier.email})`,
    value: dynamoKeysToBase32(
      courier.companyBusinessID,
      courier.email
    ),
  }));
  const courierSelectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    setChosenCourierId(newSelection ? newSelection.value : null);
  };
  const currentCourierSelectOption = courierOptions.find(
    (item) => item.value === chosenCourierId
  );

  const orderOptions: SelectOption[] = orders
    .filter((item) => !!item.id)
    .filter((item) => JSON.stringify(item.weeklyTemplate) !== '{}')
    .map((order) => ({
      label: order.name,
      value: order.id || '',
    }));
  const orderSelectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    setChosenOrderId(newSelection ? newSelection.value : null);
  };
  const currentOrderSelectOption = orderOptions.find(
    (item) => item.value === chosenOrderId
  );

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }} className="form-inline">
        <div className="form-inline">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={previousWeek}
            >
              <i className="fa fa-chevron-left"></i>
            </button>
          </div>
          &nbsp;&nbsp;
          <div className="form-group">
            <label>
              {moment(chosenWeekFirstDate).format('L')} -{' '}
              {moment(chosenWeekLastDate).format('L')}
            </label>
          </div>
          &nbsp;&nbsp;
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={nextWeek}
            >
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
          &nbsp;&nbsp;
          <div className="form-group" style={{ width: '400px' }}>
            <Select
              options={orderOptions}
              // @ts-ignore
              onChange={orderSelectionChanged}
              value={currentOrderSelectOption}
            />
          </div>
          &nbsp;&nbsp;
          <div className="form-group" style={{ width: '450px' }}>
            <Select
              options={courierOptions}
              // @ts-ignore
              onChange={courierSelectionChanged}
              value={currentCourierSelectOption}
            />
          </div>
        </div>
      </div>
      {chosenOrderId && (
        <SpecialOrderScheduleCalendarView
          startDateISO={chosenWeekFirstDate}
          chosenCourierId={chosenCourierId}
          chosenOrder={orders.find(
            (item) => item.id === chosenOrderId
          )}
          couriers={couriers}
          transl={transl}
          reload={reload}
        />
      )}
    </div>
  );
};
