import moment from 'lib/moment';
import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import Modal from 'lib/Modal';
import { TransportOrder } from '../orders/types';
import { TimeSlotSelector } from '../monitoring/TimeSlotSelector';
import { sendOrderToMyxline } from './requests';

export const SendToMyxlinePopup = ({
  closePopup,
  closeParentPopup,
  transl,
  attrs,
}: {
  closePopup: () => any;
  closeParentPopup?: () => any;
  transl: (label: string) => string;
  attrs: TransportOrder;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [sendAllFromSameSender, setSendAllFromSameSender] =
    React.useState(!attrs.specialOrderID);
  const [targetDeliveryTime, setTargetDeliveryTime] = React.useState(
    moment(attrs.earliestPickupTime ?? moment().toISOString())
      .set({
        hour:
          moment().hour() +
          (attrs.serviceLevel === 'samehour' ? 2 : 3),
        minute: Math.floor(moment().minute() / 15) * 15,
        second: 0,
        millisecond: 0,
      })
      .toISOString()
  );

  const doSend = (): void => {
    setLoading(true);
    sendOrderToMyxline(
      attrs,
      attrs.earliestPickupTime ?? moment().toISOString(),
      targetDeliveryTime,
      sendAllFromSameSender
    )
      .then(() =>
        closeParentPopup ? closeParentPopup() : closePopup()
      )
      .catch((err) => {
        alert('An error occurred: ' + err.toString());
      });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '400px',
      maxHeight: window.innerHeight - 100,
      width: '400px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'tyur'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('SendToMyxline')}</h2>
          <h4>{transl('LatestDeliveryTimeMessage')}</h4>
          <TimeSlotSelector
            pickupTime={targetDeliveryTime}
            setPickupTime={setTargetDeliveryTime}
            transl={transl}
          />
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={sendAllFromSameSender}
                onChange={(e): void =>
                  setSendAllFromSameSender((e.target as any).checked)
                }
                disabled={!!attrs.specialOrderID}
              />
              &nbsp;&nbsp;{transl('SendAllFromSameSender')}
            </label>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={doSend}
              disabled={loading}
            >
              {transl('SendToMyxline')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
