import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { UnallocatedOrdersView } from './UnallocatedOrdersView';
import { OrderPackagesView } from './OrderPackagesView';
import { MyxlineOrdersView } from './MyXLineOrdersView';

export const SamedayOrdersView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [chosenView, setChosenView] = React.useState<
    'unallocatedOrders' | 'currentOrderPackages' | 'myxlineOrders'
  >('unallocatedOrders');

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <div className="btn-group btn-group-justified">
          <span
            onClick={(): void => setChosenView('unallocatedOrders')}
            className={
              'btn btn-primary' +
              (chosenView === 'unallocatedOrders' ? ' active' : '')
            }
          >
            {transl('UnallocatedSamedayOrders')}
          </span>
          <span
            onClick={(): void =>
              setChosenView('currentOrderPackages')
            }
            className={
              'btn btn-primary' +
              (chosenView === 'currentOrderPackages' ? ' active' : '')
            }
          >
            {transl('SamedayOrderPackages')}
          </span>
          <span
            onClick={(): void => setChosenView('myxlineOrders')}
            className={
              'btn btn-primary' +
              (chosenView === 'myxlineOrders' ? ' active' : '')
            }
          >
            {transl('MyxLineOrders')}
          </span>
        </div>
      </div>
      {chosenView === 'unallocatedOrders' && (
        <UnallocatedOrdersView
          transl={transl}
          setChosenView={setChosenView}
        />
      )}
      {chosenView === 'currentOrderPackages' && (
        <OrderPackagesView transl={transl} />
      )}
      {chosenView === 'myxlineOrders' && (
        <MyxlineOrdersView transl={transl} />
      )}
    </div>
  );
};
