import React from 'react';
import moment from 'lib/moment';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import { fetchMyxlineOrders } from './requests';
import { TransportOrder } from '../orders/types';

const formatTimestamp = (stamp: string): string =>
  moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm');

const formatAddress = (
  title: string,
  shortAddress: string,
  postalCode: string
): string => `${title} (${shortAddress}, ${postalCode})`;

const ListItem = ({
  attrs,
  transl,
}: {
  attrs: TransportOrder;
  key: any;
  transl: (label: string) => string;
}): JSX.Element => (
  <tr>
    <td>{transl(attrs.state)}</td>

    <td>
      {attrs.metaData?.packageType !== 'normal' && (
        <span style={{ fontWeight: 'bold', color: 'red' }}>
          {transl('RequiresCarType')}:{' '}
          {transl('CarType' + (attrs.metaData?.packageType || ''))}
          <br />
        </span>
      )}
      {attrs.isColdDelivery && (
        <span style={{ fontWeight: 'bold', color: 'blue' }}>
          {transl('ColdDelivery')}
          <br />
        </span>
      )}
      {attrs.numPackages > 1 && (
        <span style={{ fontWeight: 'bold', color: 'black' }}>
          {transl('NumberOfPackages')}: {attrs.numPackages}
          <br />
        </span>
      )}
      {attrs.isCriticallyUrgent && (
        <span style={{ fontWeight: 'bold', color: 'red' }}>
          {transl('IsCriticallyUrgent')}
          <br />
        </span>
      )}
    </td>
    <td>{attrs.city}</td>
    <td>{attrs.myXLineId}</td>
    <td>
      {attrs.providerCompanyName} ({attrs.providerCompanyBusinessID})
    </td>
    <td>{formatTimestamp(attrs.orderPlacedTimestamp as string)}</td>
    <td>
      {!!attrs.earliestPickupTime
        ? formatTimestamp(attrs.earliestPickupTime)
        : '-'}
    </td>
    <td>
      {formatAddress(
        attrs.startAddressTitle,
        attrs.startAddress,
        attrs.startPostalCode
      )}
    </td>
    <td>{attrs.startAddressNotes}</td>
    <td>
      {formatAddress(
        attrs.recipientName,
        attrs.recipientAddress,
        attrs.recipientPostalCode
      )}
    </td>
    <td>{attrs.recipientAddressNotes}</td>
    <td>{attrs.distanceInKm.toFixed(3)}</td>
  </tr>
);

const ListHeader = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => (
  <thead>
    <tr>
      <th>{transl('State')}</th>
      <th>{transl('Notes')}</th>
      <th>{transl('City')}</th>
      <th>Myxline ID</th>
      <th>{transl('Sender')}</th>
      <th>{transl('OrderPlacedTimestamp')}</th>
      <th>{transl('PickupTime')}</th>
      <th>{transl('PickupAddress')}</th>
      <th>{transl('PickupInstructions')}</th>
      <th>{transl('RecipientAddress')}</th>
      <th>{transl('DeliveryInstructions')}</th>
      <th>{transl('DistanceinKm')}</th>
    </tr>
  </thead>
);

export const MyxlineOrdersView = ({
  transl,
}: {
  transl: (label: string) => string;
}): JSX.Element => {
  const [orders, setOrders] = React.useState(
    null as TransportOrder[] | null
  );

  React.useEffect(() => {
    fetchMyxlineOrders().then((data) => setOrders(data));
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const refresh = React.useCallback((): void => {
    setOrders(null);
    fetchMyxlineOrders().then((data) => setOrders(data));
  }, []);

  if (orders === null) {
    return <LoadingComponent />;
  }

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div>
        <div
          className="pull-left"
          style={{
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '25px',
          }}
        >
          <button
            type="button"
            className="btn btn-success btn-md"
            onClick={refresh}
          >
            <i className="fa fa-refresh" aria-hidden="true"></i>
            &nbsp;{transl('Refresh')}
          </button>
        </div>
      </div>
      <div style={commonStyles.defaultContainer}>
        <table className="table table-striped table-hover">
          <ListHeader transl={transl} />
          <tbody>
            {orders.map((attrs, i) => {
              return (
                <ListItem key={i} attrs={attrs} transl={transl} />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
