/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import { PayperiodDates, CourierHistoryDay } from './types';
import moment from 'lib/moment';

const HistoryDayItem = ({
  transl,
  attrs,
}: {
  transl: (label: string) => string;
  attrs: CourierHistoryDay;
}): JSX.Element => {
  const formatSum = (cents: number): string => {
    return (cents / 100)
      .toFixed(2)
      .split('.')
      .join(transl('DecimalSeparator'));
  };
  return (
    <div style={{ padding: '15px', border: '1px solid black' }}>
      <h4>
        {transl('Date')}: {moment(attrs.date).format('L')}
      </h4>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('State')}</th>
            <th>{transl('Timestamp')}</th>
            <th>{transl('Area')}</th>
            <th>{transl('DistanceinKm')}</th>
            <th>{transl('SumWithoutVatWithExplanation')}</th>
          </tr>
        </thead>
        <tbody>
          {attrs.orders.map((order, i) => (
            <tr key={i}>
              <td>{transl(order.type)}</td>
              <td>
                {moment(order.datetime).format('L')}{' '}
                {moment(order.datetime).format('HH:mm')}
              </td>
              <td>
                {order.fromPostalCode} - {order.toPostalCode}
              </td>
              <td>{order.totalKm.toFixed(2)}</td>
              <td>{formatSum(order.totalMoney)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const CourierHistoryPopup = ({
  closePopup,
  transl,
  historyData,
  payperiodDates,
}: {
  closePopup: () => any;
  transl: (label: string) => string;
  historyData: CourierHistoryDay[];
  payperiodDates: PayperiodDates;
}): JSX.Element => {
  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      maxHeight: window.innerHeight - 50,
      width: '650px',
      maxWidth: window.innerWidth - 100,
    },
  };
  return (
    <Modal
      isOpen={true}
      onRequestClose={closePopup}
      style={popupStyles}
      contentLabel={'hsdjfkslda'}
      shouldCloseOnOverlayClick={true}
    >
      <div style={commonStyles.popupScrollContainer}>
        <h2>{transl('OrdersByCourier')}</h2>
        <h4>
          {transl('Courier')}:{' '}
          {historyData[0].courier.split('<;;>')[1]}
          <br />
          {transl('Payperiod')}:{' '}
          {moment(payperiodDates.startDate).format('L')} -{' '}
          {moment(payperiodDates.endDate).format('L')}
          {historyData.map((historyDay) => (
            <HistoryDayItem
              key={historyDay.date}
              attrs={historyDay}
              transl={transl}
            />
          ))}
        </h4>
      </div>
    </Modal>
  );
};
