import React from 'react';

export const LoadingComponent = (): JSX.Element => (
  <div
    style={{
      margin: 'auto'
    }}
  >
    <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
);
