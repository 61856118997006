import React from 'react';
import LoginView from './LoginView';
import MainView from './MainView';
import { setLogoutCallback } from './web/tokens';
import { gettext } from './translations';

const App = (): JSX.Element => {
  const [loggedIn, changeLoginStatus] = React.useState(false);
  const [companyName, setCompanyName] = React.useState(
    null as string | null
  );

  // TODO: Add support for changing the language
  const [lang] = React.useState('fi');

  const transl = gettext(lang);

  setLogoutCallback(() => changeLoginStatus(false));

  if (!loggedIn) {
    return (
      <LoginView
        logUserIn={(): void => changeLoginStatus(true)}
        transl={transl}
        companyName={companyName}
        setCompanyName={setCompanyName}
      />
    );
  }
  return <MainView transl={transl} companyName={companyName} />;
};

export default App;
