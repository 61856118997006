import React from 'react';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import FixedTable from 'lib/FixedTable';

import moment from 'lib/moment';
import Gateway from '../web/gateway';
import { PayperiodData, CourierHistoryDay } from './types';
import { CourierHistoryPopup } from './CourierHistoryPopup';

const PayperiodView = ({
  transl,
  isMainTransportCompany,
}: {
  transl: (label: string) => string;
  isMainTransportCompany: boolean;
}): JSX.Element => {
  const [payperiod, setPayperiod] = React.useState(
    null as PayperiodData | null
  );
  const [payperiodIdx, setPayperiodIdx] = React.useState(0);
  const [payperiodPage, setPayperiodPage] = React.useState(0);

  const [popupData, setPopupData] = React.useState(
    null as CourierHistoryDay[] | null
  );

  const fetchNewPayperiod = (payperiodIndex: number): any => {
    setPayperiod(null);
    Gateway.get(
      `/transportadmin/payperiod?payperiod=${payperiodIndex}&page=0`
    ).then(setPayperiod);
  };
  const fetchPreviousPayperiod = (): any => {
    const payperiodToFetch = payperiodIdx + 1;
    fetchNewPayperiod(payperiodToFetch);
    setPayperiodIdx(payperiodToFetch);
  };
  const fetchNextPayperiod = (): any => {
    const payperiodToFetch = payperiodIdx - 1;
    if (payperiodToFetch < 0) return;
    fetchNewPayperiod(payperiodToFetch);
    setPayperiodIdx(payperiodToFetch);
  };

  React.useEffect(() => {
    fetchNewPayperiod(0);
    return (): any => {
      // Intentionally empty
    };
  }, []);

  const loadMoreInProgressRef = React.useRef(false);
  const loadMore = (): any => {
    // Prevent concurrent loadMore
    if (loadMoreInProgressRef.current) return;

    // Prevent loading more if the payperiod itself hasn't been loaded yet
    if (!payperiod) return;

    // Prevent loading if there's nothing more to load
    if (payperiodPage * payperiod.pageSize >= payperiod.resultsCount)
      return;

    loadMoreInProgressRef.current = true;

    const nextPage = payperiodPage + 1;
    setPayperiodPage(nextPage);
    Gateway.get(
      `/transportadmin/payperiod?payperiod=${payperiodIdx}&page=${nextPage}`
    ).then((res) => {
      setPayperiod({
        ...payperiod,
        results: payperiod.results.concat(res.results),
      });
      loadMoreInProgressRef.current = false;
    });
  };

  const formatSum = (cents: number): string => {
    return (cents / 100)
      .toFixed(2)
      .split('.')
      .join(transl('DecimalSeparator'));
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }} className="form-inline">
        <div className="form-inline">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={fetchPreviousPayperiod}
              disabled={!payperiod}
            >
              <i className="fa fa-chevron-left"></i>
            </button>
          </div>
          &nbsp;&nbsp;
          <div className="form-group">
            <label>
              {transl('Payday')}:{' '}
              {payperiod &&
                moment(payperiod.payperiod.payDay).format('L')}
            </label>
          </div>
          &nbsp;&nbsp;
          {payperiodIdx > 0 && (
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={fetchNextPayperiod}
                disabled={!payperiod}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          )}
        </div>
        <div>
          {transl('Payperiod')}:{' '}
          {payperiod &&
            moment(payperiod.payperiod.startDate).format('L')}
          {' - '}
          {payperiod &&
            moment(payperiod.payperiod.endDate).format('L')}
        </div>
      </div>
      {!payperiod && <LoadingComponent />}
      {!!payperiod && (
        <div style={commonStyles.defaultContainer}>
          <FixedTable
            header={
              <tr>
                <th>{transl('Email')}</th>
                <th>{transl('Name')}</th>
                <th>{transl('Phone')}</th>
                {isMainTransportCompany && (
                  <React.Fragment>
                    <th>{transl('CompanyBid')}</th>
                    <th>{transl('IBAN')}</th>
                  </React.Fragment>
                )}
                <th>{transl('NumberOfOrders')}</th>
                <th>{transl('SumWithoutVat')}</th>
                <th>{transl('Vat')}</th>
                <th>{transl('SumWithVat')}</th>
              </tr>
            }
            loadMore={loadMore}
          >
            {payperiod.results.map((item) => {
              let nofOrders = 0;
              for (const historyDay of item.courierHistory) {
                nofOrders += historyDay.orders.length;
              }
              return (
                <tr
                  key={item.email}
                  style={{ cursor: 'pointer' }}
                  onClick={(): any =>
                    item.courierHistory.length > 0 &&
                    setPopupData(item.courierHistory)
                  }
                >
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>{item.phone || ''}</td>
                  {isMainTransportCompany && (
                    <React.Fragment>
                      <td>{item.subcontractorCompanyBid || ''}</td>
                      <td>{item.iban || ''}</td>
                    </React.Fragment>
                  )}
                  <td>{nofOrders}</td>
                  <td>{formatSum(item.totalSum)}</td>
                  <td>
                    {item.vatPercentage}%<br />
                    {formatSum(
                      (item.totalSum * item.vatPercentage) / 100.0
                    )}
                  </td>
                  <td>
                    {formatSum(
                      item.totalSum +
                        (item.totalSum * item.vatPercentage) / 100.0
                    )}
                  </td>
                </tr>
              );
            })}
          </FixedTable>
        </div>
      )}
      {popupData && payperiod && (
        <CourierHistoryPopup
          closePopup={(): any => setPopupData(null)}
          transl={transl}
          historyData={popupData}
          payperiodDates={payperiod.payperiod}
        />
      )}
    </div>
  );
};

export default PayperiodView;
