import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';
import { TransportCourier } from './helpers';
import { TransportOrder } from '../orders/types';

import Gateway from '../web/gateway';

const formatTimestamp = (stamp: string | null): string => {
  if (!stamp) return '-';
  return (
    moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm')
  );
};

type HistoryListItemProps = {
  attrs: TransportOrder;
  key: any;
  transl: (label: string) => string;
  courier?: TransportCourier;
  removeOrder: () => any;
};

const HistoryListItem = ({
  transl,
  attrs,
  courier,
  removeOrder,
}: HistoryListItemProps): JSX.Element => {
  const [removeButtonDisabled, setRemoveButtonDisabled] =
    React.useState(false);
  return (
    <tr>
      <td>{attrs.externalID}</td>
      <td>{transl(attrs.state)}</td>
      <td>{formatTimestamp(attrs.orderPlacedTimestamp)}</td>
      <td>{formatTimestamp(attrs.courierPickupTimestamp)}</td>
      <td>{formatTimestamp(attrs.deliveredTimestamp)}</td>
      <td>
        {courier
          ? `${courier.name} (${courier.email})`
          : attrs.courierEmailAndCompany}
      </td>
      <td>
        {attrs.isRetroactivelyManuallyCreated && (
          <button
            type="button"
            className="btn btn-danger btn-md"
            onClick={() => {
              setRemoveButtonDisabled(true);
              removeOrder();
            }}
            disabled={removeButtonDisabled}
          >
            {transl('Remove')}
          </button>
        )}
      </td>
    </tr>
  );
};

const courierToCourierKey = (courier: TransportCourier): string =>
  courier.email + '--' + courier.companyBusinessID;

export const SpecialOrderHistoryView = ({
  transl,
  couriers,
  orders,
  refresh,
}: {
  transl: (label: string) => string;
  couriers: TransportCourier[];
  orders: TransportOrder[];
  refresh: () => any;
}): JSX.Element => {
  const [couriersByKey, setCouriersByKey] = React.useState(
    {} as { [key: string]: TransportCourier }
  );

  React.useEffect(() => {
    const byKey: any = {};
    for (const c of couriers) {
      byKey[courierToCourierKey(c)] = c;
    }
    setCouriersByKey(byKey);
  }, [couriers]);

  const removeOrder = async (orderID: string) => {
    await Gateway.post(
      `/transportadmin/order/${orderID}/remove-prices`,
      {}
    );
    refresh();
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <h3>{transl('PastOrders')}</h3>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('OrderNumber')}</th>
            <th>{transl('State')}</th>
            <th>{transl('Timestamp')}</th>
            <th>{transl('PickupTime')}</th>
            <th>{transl('DeliveryTimestamp')}</th>
            <th>{transl('Courier')}</th>
            <th>{transl('Remove')}</th>
          </tr>
        </thead>
        <tbody>
          {orders
            .filter(
              (item) =>
                item.priceForCourier || item.priceForOrderProvider
            )
            .map((order) => (
              <HistoryListItem
                transl={transl}
                attrs={order}
                key={order.transportOrderId}
                courier={
                  couriersByKey[order.courierEmailAndCompany || '']
                }
                removeOrder={() =>
                  removeOrder(order.transportOrderId)
                }
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};
