/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import Gateway from '../web/gateway';
import { ChooseCourierComponent } from '../ChooseCourierComponent';
import { dynamoKeysFromBase32 } from 'lib/helpers';

export const ChangeCourierPopup = ({
  closePopup,
  reload,
  transl,
  transportOrderId,
}: {
  closePopup: () => any;
  reload: () => any;
  transl: (label: string) => string;
  transportOrderId: string;
}): JSX.Element => {
  const [courierBase32, setCourierBase32] =
    React.useState<string | null>(null);

  const saveData = (): void => {
    if (!courierBase32) return;
    const dynamoKeys = dynamoKeysFromBase32(courierBase32);
    if (!dynamoKeys || !dynamoKeys.rangeKey) return;
    Gateway.post(`/transportadmin/order/retroactive-courier-change`, {
      data: {
        transportOrderId,
        courierCompanyBusinessId: dynamoKeys.hashKey,
        courierEmail: dynamoKeys.rangeKey,
      },
    })
      .then(reload)
      .catch((err) => alert(err));

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('ChangeCourier')}</h2>
          <ChooseCourierComponent
            transl={transl}
            selectedCourierBase32Changed={setCourierBase32}
          />
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={!courierBase32}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Confirm')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
