import React from 'react';
import Colors from 'lib/colors';
import { IS_TEST_SERVER } from './web/constants';
import Gateway from './web/gateway';

const logUserOut = (): void => {
  Gateway.post('/transportadmin/logout', {});
  window.location.reload();
};

const Header = ({
  transl,
  companyName,
}: {
  transl: (label: string) => string;
  companyName: string | null;
}): JSX.Element => (
  <div style={styles.header}>
    <img
      alt="logo"
      src="https://www.cabapp.fi/img/logo.png"
      style={styles.logo}
    />
    <h4 style={styles.headerText}>
      {companyName}{' '}
      {IS_TEST_SERVER && (
        <span>(Testiympäristö / Test Environment)</span>
      )}
    </h4>
    <div className="mobile-break-after"></div>
    <div style={styles.headerBtnContainer}>
      <span
        onClick={logUserOut}
        style={styles.headerBtn}
        className="tooltip-bottom hover-yellow"
      >
        <i className="fa fa-sign-out"></i>
        <span className="tooltiptext">{transl('Logout')}</span>
      </span>
    </div>
  </div>
);

const styles = {
  logo: {
    height: '40px',
    margin: '5px 5px 0px 5px',
  },
  header: {
    backgroundColor: Colors.main,
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    paddingBottom: '7px',
  },
  headerText: {
    color: 'white',
    flex: '1 1 auto',
    margin: '0px',
    padding: '15px',
  },
  headerBtnContainer: {
    width: '90px',
    display: 'flex',
    marginRight: '20px',
  },
  headerBtn: {
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    display: 'flex',
    textDecoration: 'none',
    color: 'white',
    fontSize: '25px',
  },
};

export default Header;
