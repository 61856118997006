import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import TransportCourierPopup from './TransportCourierPopup';
import {
  TransportCourier,
  TransportCourierCreateData,
} from './types';

import Gateway from '../web/gateway';

type TransportCourierListItemProps = {
  attrs: TransportCourier;
  setCourierPopupData: (attrs: TransportCourierCreateData) => any;
  key: any;
  transl: (label: string) => string;
};

const TransportCourierListItem = ({
  attrs,
  setCourierPopupData,
  transl,
}: TransportCourierListItemProps): JSX.Element => {
  const openPopup = (): void => {
    setCourierPopupData({
      companyBusinessID: attrs.companyBusinessID,
      email: attrs.email,
      name: attrs.name,
      phone: attrs.phone || '',
      country: attrs.country,
      language: attrs.language,

      isSubcontractor: attrs.isSubcontractor,
      isFreelancer: attrs.isFreelancer,
      iban: attrs.iban,
      subcontractorCompanyBid: attrs.subcontractorCompanyBid,
      subcontractorCompanyName: attrs.subcontractorCompanyName,
      subcontractorCompanyAddress: attrs.subcontractorCompanyAddress,
      subcontractorCompanyPostalcode:
        attrs.subcontractorCompanyPostalcode,
      subcontractorCompanyCity: attrs.subcontractorCompanyCity,
      subcontractorCompanyEmail: attrs.subcontractorCompanyEmail,
      dataloggerNo: attrs.dataloggerNo,
      previousLoggers: attrs.previousLoggers,
    });
  };
  return (
    <tr style={{ cursor: 'pointer' }} onClick={openPopup}>
      <td>{attrs.name}</td>
      <td>{attrs.email}</td>
      <td>{attrs.phone || ''}</td>
      <td>
        {attrs.subcontractorCompanyName || ''} (
        {attrs.subcontractorCompanyBid || ''})
      </td>
      <td>
        {attrs.medicalDeliveryTrainingCompleted ? (
          <span style={{ color: 'green' }}>{transl('Yes')}</span>
        ) : (
          <span style={{ color: 'red' }}>{transl('No')}</span>
        )}
      </td>
    </tr>
  );
};
const TransportCouriersView = ({
  isMainTransportCompany,
  transl,
}: {
  transl: (label: string) => string;
  isMainTransportCompany: boolean;
}): JSX.Element => {
  const [couriers, setCouriers] = React.useState(
    null as TransportCourier[] | null
  );
  const [courierPopupData, setCourierPopupData] = React.useState(
    null as TransportCourierCreateData | null
  );

  React.useEffect(() => {
    Gateway.get(`/transportadmin/couriers`).then(setCouriers);
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reloadCouriers = (): void => {
    Gateway.get(`/transportadmin/couriers`).then(setCouriers);
  };

  const openNewCourierPopup = (): void => {
    setCourierPopupData({
      companyBusinessID: '',
      email: '',
      name: '',
      phone: '',
      country: 'FI',
      language: isMainTransportCompany ? 'en' : 'fi',

      isSubcontractor: isMainTransportCompany ? true : false,
      isFreelancer: false,
      iban: null,
      subcontractorCompanyBid: null,
      subcontractorCompanyName: null,
      subcontractorCompanyAddress: null,
      subcontractorCompanyPostalcode: null,
      subcontractorCompanyCity: null,
      subcontractorCompanyEmail: null,
      dataloggerNo: null,
      previousLoggers: [],
    });
  };

  const closeCourierPopup = (): void => {
    setCourierPopupData(null);
  };

  if (couriers === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewCourierPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('NewCourier')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('Name')}</th>
              <th>{transl('Email')}</th>
              <th>{transl('Phone')}</th>
              <th>{transl('Company')}</th>
              <th>{transl('MedicalTraining')}</th>
            </tr>
          }
        >
          {couriers.map((attrs, i) => {
            return (
              <TransportCourierListItem
                key={i}
                attrs={attrs}
                setCourierPopupData={setCourierPopupData}
                transl={transl}
              />
            );
          })}
        </FixedTable>
      </div>
      {courierPopupData && (
        <TransportCourierPopup
          isMainTransportCompany={isMainTransportCompany}
          closePopup={closeCourierPopup}
          transl={transl}
          reloadCouriers={reloadCouriers}
          attrs={courierPopupData}
          isNew={courierPopupData.companyBusinessID === ''}
        />
      )}
    </div>
  );
};

export default TransportCouriersView;
