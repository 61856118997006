import Gateway from '../web/gateway';
import { SamedayOrderPackage } from './types';
import { TransportOrder } from '../orders/types';
import { CourierOnlineData } from '../monitoring/types';

export const fetchAllUnallocatedOrders = async (): Promise<
  TransportOrder[]
> => {
  return await Gateway.get('/transportadmin/all-unassigned-orders');
};

export const fetchMyxlineOrders = async (): Promise<
  TransportOrder[]
> => {
  return await Gateway.get('/transportadmin/ongoing-myxline-orders');
};

export const fetchAllOrderPackages = async (): Promise<
  SamedayOrderPackage[]
> => {
  return await Gateway.get('/transportadmin/samedayorder/packages');
};

export const fetchAllCouriersOnline = async (): Promise<
  CourierOnlineData[]
> => {
  return await Gateway.get('/transportadmin/all-couriers-online');
};

export const createTransportOrderPackage = async (
  transportOrderIds: string[],
  courierBase32: string
): Promise<void> => {
  await Gateway.post(
    `/transportadmin/samedayorder/courier/${courierBase32}/allocate`,
    {
      data: { transportOrderIds },
    }
  );
};

export const reallocateTransportOrderPackage = async (
  samedayOrdersPackageId: string,
  courierBase32: string
): Promise<void> => {
  await Gateway.post(
    `/transportadmin/samedayorder/package/${samedayOrdersPackageId}/courier/${courierBase32}/reallocate`,
    {}
  );
};

export const sendUnsentPackagesToCouriers =
  async (): Promise<void> => {
    await Gateway.post(
      '/transportadmin/samedayorder/send-unsent',
      {}
    );
  };

export const changeToSamehourOrder = async (
  transportOrderId: string
): Promise<void> => {
  await Gateway.post(
    `/transportadmin/order/${transportOrderId}/change-to-samehour`,
    {}
  );
};

export const sendOrderToMyxline = async (
  transportOrder: TransportOrder,
  pickupTimestamp: string,
  targetTimestamp: string,
  sendAllFromSameSender: boolean
): Promise<void> => {
  if (transportOrder.isColdDelivery) {
    alert('Tätä tilausta ei voi lähettää Myxlinelle!');
    return;
  }
  await Gateway.post(
    `/transportadmin/order/${transportOrder.transportOrderId}/send-to-myxline`,
    {
      data: {
        pickupTimestamp,
        targetTimestamp,
        sendAllFromSameSender,
      },
    }
  );
};

export const releaseTransportOrderPackage = async (
  samedayOrdersPackageId: string
): Promise<void> => {
  await Gateway.post(
    `/transportadmin/samedayorder/package/${samedayOrdersPackageId}/release`,
    {}
  );
};
