import React from 'react';
import Modal from 'lib/Modal';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';

import { OrderResponse } from './types';
import Gateway from '../web/gateway';

const formatTimestamp = (timestamp: string | null): string => {
  if (!timestamp) return '-';
  return (
    moment(timestamp).format('L') +
    ' ' +
    moment(timestamp).format('HH:mm')
  );
};

export const OrderDetailsPopup = ({
  closePopup,
  transl,
  orderIdBase32,
}: {
  transl: (label: string) => string;
  orderIdBase32: string;
  closePopup: () => any;
}): JSX.Element => {
  const [order, setOrder] = React.useState(
    null as OrderResponse | null
  );

  React.useEffect(() => {
    Gateway.get(`/transportadmin/order/${orderIdBase32}`).then(
      setOrder
    );
    return (): void => {
      // Intentionally empty
    };
  }, [setOrder, orderIdBase32]);

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 100,
      width: window.innerWidth - 100,
    },
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={closePopup}
      style={popupStyles}
      contentLabel={'hsdjfkslda'}
      shouldCloseOnOverlayClick={true}
    >
      <div style={commonStyles.popupScrollContainer}>
        <div style={commonStyles.defaultContainer}>
          {!order && <LoadingComponent />}
          {order && (
            <div style={commonStyles.flexColumnContainer}>
              <div style={commonStyles.flexRowContainer}>
                <div style={commonStyles.flexColumnContainer}>
                  <h3>{transl('OrderInformation')}</h3>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('OrderNumber')}
                          </td>
                          <td>{order.order.externalID}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('State')}
                          </td>
                          <td>{transl(order.order.state)}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('NumberOfPackages')}
                          </td>
                          <td>{order.order.numPackages}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('ContactlessDelivery')}
                          </td>
                          <td>
                            {order.order.allowLeaveAtDoor
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('DistanceinKmStraightline')}
                          </td>
                          <td>
                            {order.order.distanceInKm.toFixed(3)}
                          </td>
                        </tr>
                        {order.order.routeDistanceInKm && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('DistanceinKmRoute')}
                            </td>
                            <td>
                              {order.order.routeDistanceInKm.toFixed(
                                3
                              )}
                            </td>
                          </tr>
                        )}
                        {order.order.actualDistanceInKm && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('DistanceinKmActual')}
                            </td>
                            <td>
                              {order.order.actualDistanceInKm.toFixed(
                                3
                              )}
                            </td>
                          </tr>
                        )}
                        {order.order.pickupFailReason && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('PickupFailReason')}
                            </td>
                            <td>
                              {transl(order.order.pickupFailReason)}
                            </td>
                          </tr>
                        )}
                        {order.order.failureNote && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('FailureReason')}
                            </td>
                            <td>{order.order.failureNote}</td>
                          </tr>
                        )}
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('ColdDelivery')}
                          </td>
                          <td>
                            {order.order.isColdDelivery
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('IsCriticallyUrgent')}
                          </td>
                          <td>
                            {order.order.isCriticallyUrgent
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('DeliveryConfirmedByPINCode')}
                          </td>
                          <td>
                            {order.order.deliveryConfirmedByPinCode
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('RetroactivelyCreated')}
                          </td>
                          <td>
                            {order.order
                              .isRetroactivelyManuallyCreated
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('MarkedAsDeliveredByAdmin')}
                          </td>
                          <td>
                            {order.order.manuallyMarkedAsDelivered
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        {order?.order.deliveryLocationLat &&
                          order?.order.deliveryLocationLong && (
                            <tr>
                              <td style={styles.tableTitle}>
                                {transl('LocationWhenDelivered')}
                              </td>
                              <td>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://www.google.com/maps/search/?api=1&query=${order?.order.deliveryLocationLat},${order?.order.deliveryLocationLong}`}
                                >
                                  {transl('MapLocation')}
                                </a>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                  <h3>{transl('Events')}</h3>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('OrderPlacedTimestamp')}
                          </td>
                          <td>
                            {formatTimestamp(
                              order.order.orderPlacedTimestamp
                            )}
                          </td>
                        </tr>
                        {order.order.cancelledTimestamp && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('CancelledTimestamp')}
                            </td>
                            <td>
                              {formatTimestamp(
                                order.order.cancelledTimestamp
                              )}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('CourierChosenTimestamp')}
                          </td>
                          <td>
                            {formatTimestamp(
                              order.order.courierChosenTimestamp
                            )}
                          </td>
                        </tr>
                        {order.order.courierArrivalTimestamp && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('CourierArrivalTimestamp')}
                            </td>
                            <td>
                              {formatTimestamp(
                                order.order.courierArrivalTimestamp
                              )}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('PickedUpTimestamp')}
                          </td>
                          <td>
                            {formatTimestamp(
                              order.order.courierPickupTimestamp
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('DeliveredTimestamp')}
                          </td>
                          <td>
                            {formatTimestamp(
                              order.order.deliveredTimestamp
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('pickupFailed')}
                          </td>
                          <td>
                            {formatTimestamp(
                              order.order.pickupFailedTimestamp
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('ReturnedTimestamp')}
                          </td>
                          <td>
                            {formatTimestamp(
                              order.order.deliveryFailedTimestamp
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={commonStyles.flexColumnContainer}>
                  <h3>{transl('PickupPlaceInformation')}</h3>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Merchant')}
                          </td>
                          <td>
                            {order.order.providerCompanyName} (
                            {order.order.providerCompanyBusinessID})
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Address')}
                          </td>
                          <td>
                            {order.order.startAddressTitle}
                            <br />
                            {order.order.startAddress},{' '}
                            {order.order.startPostalCode}{' '}
                            {order.order.city}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('PickupInstructions')}
                          </td>
                          <td>{order.order.startAddressNotes}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Phone')}
                          </td>
                          <td>{order.order.startAddressPhone}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {order.order.viaAddresses.length > 0 && (
                    <>
                      <h3>{transl('ViaAddresses')}</h3>
                      {order.order.viaAddresses.map((address, i) => (
                        <div
                          style={{
                            borderBottom: '1px solid #ccc',
                            marginBottom: '10px',
                            paddingBottom: '10px',
                          }}
                          key={i}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td style={styles.tableTitle}>
                                  {transl('Name')}
                                </td>
                                <td>{address.title}</td>
                              </tr>
                              <tr>
                                <td style={styles.tableTitle}>
                                  {transl('Type')}
                                </td>
                                <td>
                                  {transl(
                                    address.type === 'delivery'
                                      ? 'Delivery'
                                      : 'Pickup'
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td style={styles.tableTitle}>
                                  {transl('Address')}
                                </td>
                                <td>
                                  {address.address},{' '}
                                  {address.postalCode}
                                </td>
                              </tr>
                              <tr>
                                <td style={styles.tableTitle}>
                                  {transl('DeliveryInstructions')}
                                </td>
                                <td>{address.notes}</td>
                              </tr>
                              <tr>
                                <td style={styles.tableTitle}>
                                  {transl('Phone')}
                                </td>
                                <td>{address.phone}</td>
                              </tr>
                              {address.visitedTimestamp && (
                                <tr>
                                  <td style={styles.tableTitle}>
                                    {transl('VisitedTimeStamp')}
                                  </td>
                                  <td>
                                    {formatTimestamp(
                                      address.visitedTimestamp
                                    )}
                                  </td>
                                </tr>
                              )}
                              {address.failureNote && (
                                <tr>
                                  <td style={styles.tableTitle}>
                                    {transl('FailureReason')}
                                  </td>
                                  <td>{address.failureNote}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </>
                  )}
                  <h3>{transl('CustomerInformation')}</h3>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Name')}
                          </td>
                          <td>{order.order.recipientName}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Address')}
                          </td>
                          <td>
                            {order.order.recipientAddress},{' '}
                            {order.order.recipientPostalCode}
                          </td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('DeliveryInstructions')}
                          </td>
                          <td>{order.order.recipientAddressNotes}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Phone')}
                          </td>
                          <td>{order.order.recipientPhoneNo}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Email')}
                          </td>
                          <td>{order.order.recipientEmail}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {order.order.backupAddress && (
                    <div>
                      <h3>{transl('ReturnAddressInformation')}</h3>
                      <table>
                        <tbody>
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('Address')}
                            </td>
                            <td>
                              {order.order.backupAddress},{' '}
                              {order.order.backupAddressPostalCode}
                            </td>
                          </tr>
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('Phone')}
                            </td>
                            <td>{order.order.backupPhone}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div style={commonStyles.flexColumnContainer}>
                  <h3>{transl('CourierInformation')}</h3>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Name')}
                          </td>
                          <td>{order.courier?.name}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Phone')}
                          </td>
                          <td>{order.courier?.phone || '-'}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('Email')}
                          </td>
                          <td>{order.courier?.email}</td>
                        </tr>
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('OrganizationID')}
                          </td>
                          <td>{order.courier?.companyBusinessID}</td>
                        </tr>
                        {order.courier?.currentVehicleModel && (
                          <tr>
                            <td style={styles.tableTitle}>
                              {transl('CurrentVehicle')}
                            </td>
                            <td>
                              {order.courier?.currentVehicleModel} (
                              {order.order.courierCarRegisterPlate})
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td style={styles.tableTitle}>
                            {transl('IsSubcontractor')}
                          </td>
                          <td>
                            {order.courier?.isSubcontractor
                              ? transl('Yes')
                              : transl('No')}
                          </td>
                        </tr>
                        {order.courier?.isSubcontractor && (
                          <React.Fragment>
                            <tr>
                              <td style={styles.tableTitle}>
                                {transl('CompanyBid')}
                              </td>
                              <td>
                                {
                                  order.courier
                                    .subcontractorCompanyBid
                                }
                              </td>
                            </tr>
                            <tr>
                              <td style={styles.tableTitle}>
                                {transl('CompanyName')}
                              </td>
                              <td>
                                {
                                  order.courier
                                    .subcontractorCompanyName
                                }
                              </td>
                            </tr>
                            <tr>
                              <td style={styles.tableTitle}>
                                {transl('CompanyAddress')}
                              </td>
                              <td>
                                {
                                  order.courier
                                    .subcontractorCompanyAddress
                                }
                                ,{' '}
                                {
                                  order.courier
                                    .subcontractorCompanyPostalcode
                                }
                              </td>
                            </tr>
                            <tr>
                              <td style={styles.tableTitle}>
                                {transl('IBAN')}
                              </td>
                              <td>{order.courier.iban}</td>
                            </tr>
                          </React.Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {order.pickupPhotoUrl && (
                <div>
                  <h3>{transl('PickupPhoto')}</h3>
                  <img
                    style={{ width: '95%' }}
                    src={order.pickupPhotoUrl}
                    alt=""
                  />
                </div>
              )}
              {order.viaAddressSignaturePhotoUrls &&
                order.viaAddressSignaturePhotoUrls.length > 0 && (
                  <>
                    <div>
                      <h3>{transl('ViaAddressPhotos')}</h3>
                    </div>
                    {order.viaAddressSignaturePhotoUrls.map(
                      (photoUrl, i) => (
                        <div key={i}>
                          <img
                            style={{ width: '95%' }}
                            src={photoUrl}
                            alt=""
                          />
                        </div>
                      )
                    )}
                  </>
                )}
              {order.signatureImgUrl && (
                <div>
                  <h3>
                    {transl(
                      order.order.allowLeaveAtDoor
                        ? 'LeftAtDoorImg'
                        : 'SignatureImg'
                    )}
                  </h3>
                  <img
                    style={{ width: '95%' }}
                    src={order.signatureImgUrl}
                    alt=""
                  />
                </div>
              )}
              {order.secondSignatureImgUrl && (
                <div>
                  <img
                    style={{ width: '95%' }}
                    src={order.secondSignatureImgUrl}
                    alt=""
                  />
                </div>
              )}
              {order.loggerReadingPhotoUrl && (
                <div>
                  <h3>{transl('LoggerReadingPhoto')}</h3>
                  <img
                    style={{ width: '95%' }}
                    src={order.loggerReadingPhotoUrl}
                    alt=""
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  tableTitle: { fontWeight: 'bold' as 'bold', paddingRight: '15px' },
};
